import React, { useState } from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import { Box, Card, CardContent, Divider, Grid, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ProfileCard from './ProfileCard';
import Membership from './Membership';
import Password from './Password';
import Payment from './Payment';
import TrustVerification from './TrustVerification';

const Profile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
      setTabIndex(newIndex);
    };
  return (
    <div>
       <FreelancerNavbar/>


       <div style={{width:'100%',display:'flex',justifyContent:'center',paddingTop:'90px'}}>


       <Box  className='tabBox'>
      {/* Sidebar Tabs */}
      <Box minWidth="200px" borderRight="1px solid #e0e0e0" pr={3} >
        <Tabs
         orientation={isMobile ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={tabIndex}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          style={{display:'flex',alignItems:'flex-start'}}
        >
          <Tab label="Profile" />
          <Tab label="Membership" />
          <Tab label="Password" />
          <Tab label="Payment & Financials" />
          <Tab label="Trust & Verification" />
          
        </Tabs>
      </Box>

      {/* Profile Details Form */}
      <Box p={3} flex="1">
        {tabIndex === 0 && (
         <ProfileCard/>
        )}

    {tabIndex === 1 && (
         <Membership/>
        )}

     {tabIndex === 2 && (
         <Password/>
        )}

{tabIndex === 3 && (
         <Payment/>
        )}

{tabIndex === 4 && (
         <TrustVerification/>
        )}
      </Box>
    </Box>

    </div>
    </div>
  )
}

export default Profile

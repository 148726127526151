import React from 'react'
import { FaFlag } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { HiMiniCurrencyDollar } from "react-icons/hi2";

const ClientInfo = () => {
  return (
    <div>
      <div className='freelancer-name-div'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px"}}>
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5  style={{color:"black"}}>About the Client</h5>
            </div>

            <div style={{display:"flex",flexDirection:"column",gap:"5px",padding:"20px 0"}}>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Sahjah</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>America</div>
                </div>
                <div className='freelancer-name-div' style={{display:"flex",gap:"20px"}}>
                    <div style={{display:'flex',alignItems:'center',gap:"4px"}}>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        <CiStar style={{fontSize:"25px"}}/>
                        0.0
                    </div>
                    <div style={{display:'flex',alignItems:'center',gap:"8px"}}>
                        <BsChatLeftDotsFill style={{fontSize:"25px"}}/>
                        0
                    </div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Member since Sep 3, 2024</div>
                </div>
            </div>



            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5  style={{color:"black"}}>Client Engagement</h5>
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:"5px",padding:"20px 0"}}>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Sahjah</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>America</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Member since Sep 3, 2024</div>
                </div>
            </div>


            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5  style={{color:"black"}}>Client Engagement</h5>
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:"5px",padding:"20px 0"}}>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Payment verified</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Identity verified</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div> Deposit made</div>
                </div>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <FaFlag/>
                    <div>Email verified</div>
                </div>
            </div>

        </div>

        <div  className='freelancer-name-div'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px",margin:"20px 0"}}>
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5  style={{color:"black"}}>Free Member</h5>
            </div>

            <div>6 bids left out of 6</div>
        </div>

        <div  className='freelancer-name-div hide'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px",marginTop:"20px"}}>
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5  style={{color:"black"}}>How to write a winning bid</h5>
            </div>

            <div>Your best chance of winning this project is writing a great bid proposal here!</div>

            <div style={{paddingTop:"12px"}}>Great bids are ones that:</div>
            <ul>
                <li>Are engaging and well written without spelling or grammatical errors</li>
                <li>Show a clear understanding of what is required for this specific project - personalize your response!</li>
                <li>Explain how your skills & experience relate to the project and your approach to working on it</li>
                <li>Ask questions to clarify any unclear details</li>
            </ul>

            <div style={{paddingTop:"12px"}}>Most of all - don't spam or post cut-and-paste bids. You will be penalized or banned if you do so.</div>

            <button style={{display:"flex",width:"100%", border:"1px solid gray",borderRadius:"5px",justifyContent:"center",padding:"5px 0",marginTop:"20px"}}>Learm More</button>

        </div>
    </div>
  )
}

export default ClientInfo

import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import Bids from './Bids'
import CurrentWork from './CurrentWork'
import PastWork from './PastWork'
import PostProject from './client/PostProject'

const MyProject = () => {
    const [client,setClient] = useState('freelancer')
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
      setTabIndex(newIndex);
    };
  return (

    <>
        <FreelancerNavbar/>
    <div style={{width:'100%',display:'flex',justifyContent:'center',paddingTop:'90px'}}>
      <div style={{width:'80%'}}>
        <div style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <Typography variant='h5' style={{color:'black',fontWeight:'500'}}>Projects , Works and Quotes</Typography>
        <div style={{display:'flex',alignItems:'center'}}>

            <button onClick={()=> setClient('client')} className={client === 'client' ? 'clientButton':''} style={{borderRadius:'3px 0px 0px 3px',border:'0.5px solid rgba(0,0,0,0.3)',background:'transparent',height:'35px',fontWeight:'500'}}>As client</button>

            <button onClick={()=> setClient('freelancer')} className={client === 'freelancer' ? 'clientButton':''} style={{borderRadius:'0px 3px 3px 0px',border:'0.5px solid rgba(0,0,0,0.3)',background:'transparent',height:'35px',fontWeight:'500'}}>As freelancer</button>

        </div>
        </div>
{
    client === 'freelancer' ? (

        <Box  style={{marginTop:'40px'}}>
      {/* Sidebar Tabs */}
      <Box  >
        <Tabs
         orientation='horizontal'
          variant="scrollable"
          value={tabIndex}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          style={{display:'flex',alignItems:'flex-start'}}
        >
          <Tab label="Bids" />
          <Tab label="Current Work" />
          <Tab label="Past Work" />
          <Tab label="Quotes" />
         
          
        </Tabs>
      </Box>

      {/* Profile Details Form */}
      <Box p={3} flex="1">
        {tabIndex === 0 && (
         <Bids/>
        )}

   {tabIndex === 1 && (
         <CurrentWork/>
        )}

{tabIndex === 2 && (
         <PastWork/>
        )}

      </Box>
    </Box>
    ):(

        <Box  style={{marginTop:'40px'}}>
        {/* Sidebar Tabs */}
        <Box  >
          <Tabs
           orientation='horizontal'
            variant="scrollable"
            value={tabIndex}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            style={{display:'flex',alignItems:'flex-start'}}
          >
            <Tab label="Open Projects" />
            <Tab label="Current Work" />
            <Tab label="Past Work" />
            <Tab label="Quotes" />
           
            
          </Tabs>
        </Box>
  
        {/* Profile Details Form */}
        <Box p={3} flex="1">
          {tabIndex === 0 && (
           <PostProject/>
          )}
  
     {tabIndex === 1 && (
           <CurrentWork/>
          )}
  
  {tabIndex === 2 && (
           <PastWork/>
          )}
  
        </Box>
      </Box>
    )
}


      </div>
    </div>
    </>
  )
}

export default MyProject

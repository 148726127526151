import Axios from 'axios';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import toastr from 'reactjs-toastr/lib/react-toast';

const Reward = ({setOpen,detail,cookieData,bidId,setDetails}) => {
    const [bidAmount, setBidAmount] = useState(bidId?.price);
    const [deliveryDays, setDeliveryDays] = useState('7');
    const [method,setMethod] = useState('fixed')

    const createAward = async() => {
        const data = {
          "bidder_id":bidId?.user_id,
          "amount":bidAmount,
          "status":"pending",
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "project_id": detail?.id,
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_payment`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
            toastr.success('Your award has created!')
            setDetails('payment')
            setOpen(false)
              console.log(response,'response')
              return true
           }
      }
  return (
    <div style={{width:'100%',height:'auto'}}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            {
                method === 'fixed'? (
       <div style={{fontSize:'20px',fontWeight:'500'}}>Set up your Fixed Price Project</div>
                ):(
                    <div style={{fontSize:'20px',fontWeight:'500'}}>Set up your Hourly Project</div>
                )
            }
            
            <IoClose onClick={()=>setOpen(false)} style={{fontSize:'22px',cursor:'pointer'}}/>
        </div>
        <div>Please choose to award your freelancer with a fixed price or hourly price.</div>
        <hr></hr>
        <div className='column2' style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{ marginBottom: "20px",width:"46%" }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>Bid Amount</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <span style={{ marginRight: "8px" }}>$</span>
                        <input type="number"
                            value={bidAmount}
                            onChange={(e) => setBidAmount(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>USD</span>
                        </div>
                    </div>

{
    method === 'fixed' ? (
<div style={{ marginBottom: "20px",width:"46%"  }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>This project will be delivered in</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <input type="number"
                            value={deliveryDays}
                            onChange={(e) => setDeliveryDays(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>Days</span>
                        </div>
                    </div>
    ):(
        <div style={{ marginBottom: "20px",width:"46%"  }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>This project will be delivered in</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <input type="number"
                            value={deliveryDays}
                            onChange={(e) => setDeliveryDays(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>Hours</span>
                        </div>
                    </div>
    )
}
                    

                </div>

                <hr></hr>
                <div style={{fontSize:'24px',fontWeight:'500'}}>Total Price : ${bidAmount}</div>

                <div style={{marginTop:'40px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    {
                        method === 'fixed' ? (
                    <div onClick={()=>setMethod('hourly')} style={{fontWeight:'500',cursor:'pointer'}}>I prefer to pay hourly price</div>

                        ):(
                    <div onClick={()=>setMethod('fixed')} style={{fontWeight:'500',cursor:'pointer'}}>I prefer to pay fixed price</div>

                        )
                    }
                    
                    <button onClick={createAward} style={{border:'1px solid black', borderRadius:'3px',padding:'2px 10px'}}>Award</button>
                </div>
    </div>
  )
}

export default Reward

import React, { useEffect, useState } from 'react'
import { FaFlag } from "react-icons/fa";
import PlaceBid from './PlaceBid';
import Axios from 'axios';



const ProjectDetails = ({detail,cookieData,bidsData,bidId,details}) => {
    
   console.log(detail,'detail')
   let skillsArray = [];
   let skillsString = "";

   if (detail) {
     try {
       skillsArray = JSON.parse(detail?.skills_required);
       skillsString = skillsArray.join(", ");
     } catch (error) {
       console.error("Failed to parse skills_required:", error);
     }
   } else {
     console.warn("skills_required is undefined or empty");
   }

   const [bid,setBid] = useState({});
   const handleGetBid = async() => {
    if(Object.keys(cookieData)?.length > 0 && bidId ){
    const data = {
      "user_id":cookieData?.id,
      "api_key":cookieData?.api_key,
       "id":bidId
      
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_bid_by_id`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response){
         setBid(response?.data?.data)
          console.log(response,'response')
          return true
       }
    }
  }

  console.log(bid,'bids')

  useEffect(()=>{
   handleGetBid();
  },[details])
  return (
    <div>
        <div className='freelancer-name-div'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px"}}>
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5 className='hide' style={{color:"black"}}>Project Details</h5>
                <div>
                    <div style={{color:"black",fontSize:"16px",fontWeight:"600"}}>{detail?.cost_estimation}</div>
                    <div style={{color:"black",fontSize:"11px",fontWeight:"bold"}}> Bidding ends in 6 days, 23 hours</div>
                </div>

            </div>

            <div style={{color:"black",paddingTop:"20px"}}>
                <div>{detail?.title}</div>
                <br />
                <div>{detail?.description}</div>

                <div style={{color:"black",paddingTop:"20px",fontWeight:"bold"}}>Skills Required</div>
                <div style={{display:"flex",paddingTop:"16px",flexWrap:"wrap",gap:"12px"}}>
                  
                    {
                        skillsArray?.map((item)=>(
                            <div>
                            <a href="" style={{color:"black",padding:"5px 18px",border:"1px solid #E2E2E4",borderRadius:"20px"}}>{item}</a>
                            </div>
                        ))
                    }
                   
                    
                </div>

                <div style={{display:"flex",justifyContent:"space-between",paddingTop:"8px"}}>
                    <div style={{color:"black",fontSize:"11px"}}>Project ID: 38692436</div>
                    <div style={{color:"black",fontSize:"11px",display:"flex",alignItems:"center",cursor:"pointer",gap:"5px"}}><FaFlag/>Report Project</div>

                </div>

                <div style={{border: "2px solid #FFA500",backgroundColor: "#FFF9F5",padding: "16px",borderRadius: "4px",display: "flex",alignItems: "center",margin: "20px 0",width:'100%'}}>
                    <div className='hide' style={{ marginRight: "12px" }}>
                        <span style={{fontSize: "24px",color: "#FFA500"}}>⚠️</span>
                    </div>
                    <div>
                        <strong style={{ color: "black", display: "block" }}>Beware of scams</strong>
                        <span>If you are being asked to pay a security deposit, or if you are being asked to chat on Telegram, WhatsApp, or another messaging platform, it is likely a scam. Report these projects or contact Support for assistance. 
                        <a 
                            href="#" style={{color: "#0073e6",textDecoration: "none",marginLeft: "5px"}}>
                            Learn more
                        </a>
                        </span>
                    </div>
                    </div>
            </div>

        </div>
            <div>
                { detail?.user_id !== cookieData?.id &&
                (

                    <PlaceBid detail={detail} bidsData={bidsData} bidId={bidId} details={details} bid={bid}/>

                )}
            </div>

    </div>
  )
}

export default ProjectDetails

import { Card, CardContent, Divider, Typography } from '@material-ui/core'
import React from 'react'

const Password = () => {
  return (
    <div>
      <Card>
        <CardContent>
        <Typography variant="h5" gutterBottom style={{fontWeight:'600'}}>
              Password
            </Typography>

            <Divider style={{margin:'20px 0px'}} />

            <Typography variant="h6" gutterBottom style={{fontWeight:'600'}}>
              Change Password
            </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default Password

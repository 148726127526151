import { Card, CardContent } from '@material-ui/core'
import { Rating } from '@material-ui/lab';
import React from 'react'

const ReviewPage = () => {
  const [value, setValue] = React.useState(2);
  return (
    <>
      <div style={{width:'100%',display:'flex',marginTop:'20px'}}>
       <div style={{width:'70%'}}>
        <Card style={{height:'auto'}}>
            <CardContent>
                <div style={{fontWeight:'500',fontSize:'18px'}}>Send a quote to your client to get more work </div>
                <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
                <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        size='large'
      />
<div style={{fontWeight:'500',fontSize:'18px'}}>5.0</div>
                </div>

                <div style={{width:'100%'}}>
                  <div style={{fontWeight:'500'}}>Comment</div>
                  <textarea required rows="4" placeholder='Enter comment'    style={{border:"1px solid black",resize:"vertical", width: "100%", borderRadius:"5px",fontSize:"18px"}}></textarea>
                </div>

                <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                  <button style={{border:'1px solid black',borderRadius:'3px',padding:'3px 10px'}}>Rating</button>
                </div>
            </CardContent>
        </Card>
       </div>
    </div>
    </>
  )
}

export default ReviewPage

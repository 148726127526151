import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { TfiFolder } from "react-icons/tfi";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const RecentProject = ({detail}) => {

  const [projectData,setProjetData] = useState();
  const history = useHistory();

  let skillsArray = [];
   let skillsString = "";

   if (detail) {
     try {
       skillsArray = JSON.parse(detail?.skills_required);
       skillsString = skillsArray.join(", ");
     } catch (error) {
       console.error("Failed to parse skills_required:", error);
     }
   } else {
     console.warn("skills_required is undefined or empty");
   }

  const handleProjectList = async() => {

    const data = {
      "page": 1,
      "limit": 10,
      "status": "open"
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_all_projects`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response){
        setProjetData(response?.data?.response)
          console.log(response,'response')
          return true
       }
  }

  console.log(projectData,'project')


  useEffect(()=>{
      handleProjectList();
  },[])
  return (
    <div style={{width:'100%',paddingLeft:'20px'}}>
        <div style={{fontSize:'18px',fontWeight:'500',color:'black',textAlign:'left'}}>Recent Projects</div>
<div style={{height:'520px',overflowY:'scroll',width:'100%',scrollbarWidth:'thin'}}>

{
  projectData && projectData.map((item,index)=>{

    let skillsArray = [];
          let skillsString = "";
          try {
            skillsArray = JSON.parse(item?.skills_required);
            skillsString = skillsArray && skillsArray.join(", ");
          } catch (error) {
            console.error("Failed to parse skills_required:", error);
          }

          return (

        <div onClick={()=>history.push(`/projects/${item?.id}`)} key={index} style={{display:'flex',gap:'20px',alignItems:'center',marginTop:'10px',cursor:'pointer'}}>
          <div><TfiFolder style={{fontSize:'40px'}}/></div>
          <div>
            <div style={{fontWeight:'600',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'280px'}}>{item?.title}</div>
            <div style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'280px'}}>{skillsString}</div>
            <div style={{display:'flex',justifyContent:'space-between',maxWidth:'280px'}}>
                <div>{item?.cost_estimation}</div>
                <div>2 minutes ago</div>
            </div>
          </div>
       </div>
          )
})
}
</div>


    </div>
  )
}

export default RecentProject

import React from 'react'

const Messages = ({chatUser}) => {
  return (
    <div style={{width:'100%'}}>
       <div style={{width:'100%'}}>
           <div style={{padding:'20px',width:'100%'}}>
        <div style={{fontSize:'18px',fontWeight:'500',color:'black',textAlign:'left'}}>Recent Messages</div>

{
  chatUser && chatUser.map((chat,index)=>(

        <div key={index} style={{display:'flex',gap:'20px',marginTop:'10px',width:'100%',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',padding:'10px'}}>
          <div style={{height:'40px',width:'40px',border:'1px solid black'}}>
            <img src={chat?.avatar} alt='avatar'/>
          </div>
          <div>
            <div style={{display:'flex',justifyContent:'space-between',width:'270px'}}>
            <div style={{color:'black'}}>@{chat?.full_name}</div>
                <div>2 minutes ago</div>
            </div>
            {chat.message && chat.message.slice(-1).map((msg,index)=>(
            <div key={index} style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'280px',fontSize:'16px',marginLeft:'20px'}}>
              {msg?.message}</div>
            ))}
          </div>
       </div>
  ))
}
    </div>
    </div>
    </div>
  )
}

export default Messages

import React, { Component, createRef } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { RxVideo } from "react-icons/rx";
import $ from 'jquery'
import { Avatar, Dialog, Tooltip } from '@material-ui/core';
import { MdContentCopy } from "react-icons/md";
import Modal from './Modal';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { IoClose } from 'react-icons/io5';
import { TfiClose } from 'react-icons/tfi';


const headers = {
    'Content-Type': 'text/plain'
};
export default class InnerSidebar extends Component {

    constructor(props) {
        super(props)
        let data = Cookies.getJSON('name');
        this.loginData = data?.user_data
        this.state = {
            listGroup: [],
            openSideBar:false,
            refCode:{},
            isModalOpen: false,
            
        }
       

        this.inputRef = createRef();

      
    }

    componentDidMount() {
        this.groupAPI()
        this.showDisplayNameAPI()
        this.BannerImageAPI()
        this.showRefferalCodeAPI()
        setInterval(() => {
            this.BannerImageAPI()
        }, 10000);
        var urlGroup = window.location.hash.split('groupdetail/')[1]
        this.setState({
            urlGroup: urlGroup
        })
        $(".sidebar-dropdown > a").click(function () {
            $(".sidebar-submenu").slideUp(250);
            if (
                $(this)
                    .parent()
                    .hasClass("active")
            ) {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .parent()
                    .removeClass("active");
            } else {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .next(".sidebar-submenu")
                    .slideDown(250);
                $(this)
                    .parent()
                    .addClass("active");
            }
        });

        $("#toggle-sidebar").click(function () {
            $(".page-wrapper").toggleClass("toggled");
        });
    }



    handleCopy = () => {
        const { link } = this.state;
        navigator.clipboard.writeText(link).then(() => {
          alert('Link copied to clipboard!');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      };


      handleShareClick = () => {
        this.setState({ isModalOpen: true });
      };
    
      handleCloseModal = () => {
        this.setState({ isModalOpen: false });
      };

    //==================================  Detail of Group List  ==============================

    groupAPI() {
        $('#main_loader').show();
        $('#root').css('opacity', '0.5');
        axios.post(`${process.env.REACT_APP_URL}/api/users/group_list`, { 'user_id': this.loginData?.id, 'view_user_id': this.loginData?.id, 'api_key': this.loginData?.api_key }, { headers }).then((res) => {
            this.codeDataGroup = res.data.code
            if (this.codeDataGroup === true) {
                this.setState({
                    listGroup: res.data.recdata
                });
            }
            $('#main_loader').hide();
            $('#root').css('opacity', '1.0');
        }).catch((error) => {
        });
    }

    loading(id) {
        $('#main_loader').show();
        $('#root').css('opacity', '0.5');
        setTimeout(() => {
            window.location.hash = '/timeLine/' + id;
            window.location.reload()
            $('#main_loader').hide();
            $('#root').css('opacity', '1.0');
        }, 200);
    }

    groupDetail(id) {
        $('#main_loader').show();
        $('#root').css('opacity', '0.5');
        setTimeout(() => {
            window.location.hash = '/timeLine/' + id;
            $('#main_loader').hide();
            $('#root').css('opacity', '1.0');
        }, 200);
    }

    //=====================================   Show Display NAme    ====================================

    showDisplayNameAPI() {
        $('#main_loader').show();
        $('#root').css('opacity', '0.5');
        axios.post(`${process.env.REACT_APP_URL}/api/users/show_display_name`, { 'user_id': this.loginData?.id, 'api_key': this.loginData?.api_key }, { headers }).then((res) => {
            this.codeDataDisplayName = res.data.code
            if (this.codeDataDisplayName === true) {
                this.setState({
                    list: res.data
                });
            }
            $('#main_loader').hide();
            $('#root').css('opacity', '1.0');
        }).catch((error) => {
        });
    }


    Logout() {
        Cookies.remove('name');
        setTimeout(() => {

            window.location.hash = '/login';
            window.location.reload(true)

        }, 200);
    }

     //=====================================   Show Refferal code    ====================================

     showRefferalCodeAPI() {

        axios.post(`${process.env.REACT_APP_URL}/api/users/show_referral_code`,
         { 'user_id': this.loginData?.id, 
         'api_key': this.loginData?.api_key 
        },{headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }}).then((res) => {
            //on success
           
            if (res.data.code === true) {
                this.setState({
                    refCode: res.data
                });
console.log(this.state.refCode,'refcode')

            }
          
        }).catch((error) => {
            console.log(error,'error')
        });

    }

    //==================================  Detail of Avatar Banner  ==============================


    BannerImageAPI() {
 
        axios.post(`${process.env.REACT_APP_URL}/api/users/avatar_banner`, { 'user_id': this.loginData?.id, 'view_user_id': this.loginData?.id, 'api_key': this.loginData?.api_key }, { headers }).then((res) => {
            //on success
            this.codeDataAvatarBanner = res.data.code
            if (this.codeDataAvatarBanner === true) {
                this.setState({
                    bannerImage: res.data.recdata
                });
                
            }


        }).catch((error) => {
           console.log(error)
        });

    }

    render() {
        const { isModalOpen } = this.state;
        const title = 'Check this out!';
        const  link  = `${process.env.REACT_APP_CODE}/#/register/?refcode=${this.state?.refCode.referral_code}`;
        return (
            <div className="col-lg-3 col-md-0  ">
                <aside className="sidebar fixed main-sidebar" style={{top:"115px"}}>
                    <div className="widget" style={{ height: '100vh', overflowY: 'auto', width: '350px', marginLeft: '-10px' }}>
                        <br />
                        <ul className="naves" style={{gap:'10px'}}>
                            <li id="li_self" style={{marginBottom:'15px'}}>
                                {/* <img style={{width:"35px", height:"35px"}} src={this.state.bannerImage?.avatar}/>
                                 */}
                                 <Avatar src={this.state.bannerImage?.avatar} alt=''/>
                                {/* <i className="ti-user"></i> */}
                                <Link to={`/timeLine/${this.loginData?.id}`} style={{marginLeft: '7px', top:"0px",zIndex:999999}} onClick={this.loading.bind(this, this.loginData?.id)}>{this.state.list?.display_name} </Link>
                            </li>
                            <li id="li_dashboard" style={{marginBottom:'15px'}}>
                                <i className="fa fa-home" aria-hidden="true"></i>
                                <Link to="/dashboard" > Home </Link>
                            </li>
                            <li id="li_dashboard" style={{marginBottom:'15px'}}>
                               
                                <Link to="/vtube">
                                    <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                <RxVideo style={{fontSize:'22px'}}/>
                                     V Tube 
                                    </div>
                                     </Link>
                            </li>
                            <li id="li_wallet" style={{marginBottom:'15px'}}>
                                <i className="ti-wallet"></i>
                                <Link to="/wallet" title="" > Wallet </Link>
                            </li>
                            
                            <li id="li_wallet" style={{marginBottom:'15px'}}>
                                {/* <i className="ti-wallet"></i> */}
                                <img style={{ color: '#999', }} src="https://victus.club/Projects_icon_small.png" />
                                <Link to="/freelancer" title="" > Freelancer </Link>
                            </li>
                            <li className="sidebar-dropdown" style={{marginBottom:'15px'}}>
                                <a href="javascript:;" className="group_arrow" style={{ marginLeft: '23px' }}> <img style={{ color: '#999', marginLeft: '-28px' }} src="https://victus.club/Projects_icon_small.png" /><span>Freelancing</span></a>
                                <div className="sidebar-submenu" style={{ display: 'none' }}>
                                    <ul>
                                        <li id="li_projects">
                                            <Link to="/createproject" title="" >  Create New Project </Link>
                                        </li>
                                        <li id="li_my_projects"><Link to="/MyProject" ><span>My Projects</span> </Link></li>
                                        <li id="li_discovery_projects"><Link to="/DiscoveryProject" ><span>Discover Projects</span> </Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="sidebar-dropdown" style={{marginBottom:'15px'}}>
                                <a href="javascript:;" className="group_arrow" style={{ marginLeft: '23px' }}>  <i className="fa fa-users" style={{ color: '#999', marginLeft: '-42px' }} aria-hidden="true"></i><span>Groups</span></a>
                                <div className="sidebar-submenu" style={{ display: 'none' }}>
                                    <ul>
                                        <li id="li_creategroup"><Link to="/creategroup" >
                                            <span>Create Group</span> </Link></li>
                                        <li id="li_my_groups"><Link to="/MyGroup" ><span>My Groups</span> </Link></li>
                                        <li id="li_discovery_groups"><Link to="/discoverygroups" ><span>Discover Group</span> </Link></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="sidebar-dropdown" style={{marginBottom:'15px'}}>
                                <a href="javascript:;" className="group_arrow" style={{ marginLeft: '23px' }}>  <i className="fa fa-users" style={{ color: '#999', marginLeft: '-42px' }} aria-hidden="true"></i><span>Surveys</span></a>
                                <div className="sidebar-submenu" style={{ display: 'none' }}>
                                    <ul>
                                        <li id="li_creategroup"><Link to="/voteAdd" >
                                            <span>Add Survey</span> </Link></li>
                                        <li id="li_my_groups"><Link to="/MyVotes" ><span>My Survey</span> </Link></li>
                                        <li id="li_discovery_groups"><Link to={`/voteDiscovery`} ><span>Discover Survey</span> </Link></li>
                                    </ul>
                                </div>
                            </li>

                            <li id="li_analytics_traffic" style={{marginBottom:'15px'}}>
                                <i className="fa fa-bar-chart-o"></i>
                                <Link to="/analyticstraffic" title=""> Analytics </Link>
                            </li>
                            <li id="li_settings" style={{marginBottom:'15px'}}>
                                <i className="fa fa-gear"></i>
                                <Link to="/setting" title=""> Settings  </Link>
                            </li>

                            <li id="li_settings" style={{marginBottom:'15px'}}>
                                <i className="fa fa-user-o"></i>
                                <Link to={`/timeLine/${this.loginData?.id}`} title=""> Profile </Link>
                            </li>

                            <li id="li_settings" onClick={this.Logout} style={{marginBottom:'15px'}}>
                                <i className="fa fa-sign-out"></i>
                                <Link to="/login" title=""> Logout  </Link>
                            </li>

                            <li style={{marginBottom:'15px'}}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          value={link}
          readOnly
          ref={this.inputRef}
          style={{ marginRight: '10px', padding: '0px 5px',border:'none' }}
        />

        <Tooltip title="Copy" arrow>
        <button onClick={this.handleCopy} style={{border:'none',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
          <MdContentCopy style={{color:'black'}}/> {/* Using FontAwesome for the copy icon */}
        </button>
        </Tooltip>
      </div>
                            </li>

                            <li style={{marginBottom:'15px'}}>
                                <button onClick={this.handleShareClick} style={{padding:'2px 10px',borderRadius:'10px'}}>Invite</button>
                            </li>

                                <Dialog open={isModalOpen} onClose={this.handleCloseModal}>
                     <div className="share-options">

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p style={{fontSize:'24px',fontWeight:'300'}}>Share Code</p>
                            <TfiClose onClick={this.handleCloseModal} style={{fontSize:'20px',cursor:'pointer'}}/>
                        </div>

                        <hr></hr>

                        <div style={{display:'flex',alignItems:'center',gap:'20px',justifyContent:'center',marginTop:'10px'}}>

            <FacebookShareButton url={link} quote={title}>
              <FacebookIcon size={52} round />
            </FacebookShareButton>

            <WhatsappShareButton url={link} title={title} separator=":: ">
              <WhatsappIcon size={52} round />
            </WhatsappShareButton>

            <TwitterShareButton url={link} title={title}>
              <TwitterIcon size={52} round />
            </TwitterShareButton>

            <LinkedinShareButton url={link} title={title} summary={title}>
              <LinkedinIcon size={52} round />
            </LinkedinShareButton>

            <EmailShareButton url={link} title={title}>
                <EmailIcon size={52} round/>
            </EmailShareButton>
                        </div>

                        <hr></hr>

                        <div style={{ display: 'flex', alignItems: 'center',border:'1px solid rgba(0,0,0,0.2)' ,marginTop:'10px',padding:'10px',borderRadius:'5px 10px'}}>
        <input
          type="text"
          value={link}
          readOnly
          ref={this.inputRef}
          style={{ marginRight: '10px', padding: '0px 5px',border:'none' }}
        />

        <Tooltip title="Copy" arrow>
        <button onClick={this.handleCopy} style={{border:'none',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px'}}>
          <MdContentCopy style={{color:'black'}}/> {/* Using FontAwesome for the copy icon */}
        </button>
        </Tooltip>
      </div>
          </div>
        </Dialog>
                        </ul>
                    </div>
                </aside>
            </div>
        )
    }
}
import React from 'react'
import FreelancerNavbar from './FreelancerNavbar'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Freelancer = () => {
    const history = useHistory()
  return (
    <div>
        <FreelancerNavbar/>
        <div style={{paddingTop:"80px",width:"100%",backgroundColor:"#0D1B2B",height:"100vh"}} >
            <div style={{paddingTop:"80px",width:"90%",margin:"0 auto"}}>
                <div id='freelancertext' >
                     Hire the best freelancers for any job, online.
                </div>
                <div style={{color:"white",paddingTop:"20px"}}>
                    <ul>
                        <li style={{fontSize:"24px",lineHeight:"1.33"}}>World's largest freelance marketplace</li>
                        <li style={{fontSize:"24px",lineHeight:"1.33"}}>Any job you can possibly think of</li>
                        <li style={{fontSize:"24px",lineHeight:"1.33"}}>Save up to 90% & get quotes for free</li>
                        <li style={{fontSize:"24px",lineHeight:"1.33"}}>Pay only when you're 100% happy</li>
                    </ul>
                </div>
                
                <div id='freelancerbuttons' style={{display:"flex",gap:"20px",paddingTop:"20px"}}>
                    <button onClick={()=>history.push("/post-project")} style={{padding:"16px 40px",color:"white",backgroundColor:"red", borderRadius:"7px",border:"none",backgroundColor:"#D3B06B",fontSize:"22px",fontWeight:"500"}}>Hire a Freelancer</button>
                    
                    <button onClick={()=>history.push("/freelancer/dashboard")} style={{padding:"16px 40px",color:"white",backgroundColor:"red", borderRadius:"7px",border:"none",backgroundColor:"transparent",fontSize:"22px",fontWeight:"500",border:"1px solid white"}}>Earn Money Freelancing</button>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Freelancer

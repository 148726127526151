import { Avatar, Tooltip, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from "react-icons/io";
import { IoSearch } from 'react-icons/io5'
import { MdMenu, MdSubscriptions } from 'react-icons/md'
import Cookies from 'js-cookie';
import { IoNotifications } from "react-icons/io5";
import { FaFolderOpen } from "react-icons/fa";
import { MdChatBubble } from "react-icons/md";
import { TiArrowSortedUp } from "react-icons/ti";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RecentProject from './RecentProject';
import Notifications from './Notifications';
import Messages from './Messages';
import Axios from 'axios';

const FreelancerNavbar = ({cookieData,details,detail}) => {
  const history = useHistory();
  const [chatUser, setChatUser] = useState();
  const currentChating = async() => {
   
    const data = {
      "user_id":cookieData?.id,
      "api_key":cookieData?.api_key
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/current_chat`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });

       if(response.data.code === true){
           setChatUser(response?.data?.users)
       }
    
};

useEffect(() => {
  currentChating()
},[details,cookieData])

console.log(chatUser,'chat')
  return (
    <div>
        <div  style={{zIndex:"999",backgroundColor:"white",position:'fixed',width:"100%", display:"flex",justifyContent:"space-between",padding:"0px 10px",boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
         <div style={{display:'flex',alignItems:'center',gap:'10px',maxWidth:"100px"}}>
            <MdMenu id='MenuBarLogo'  style={{fontSize:'25px',cursor:"pointer"}}/>
           <img onClick={()=>history.push("/freelancer/dashboard")} className='vtubeLogo' 
           src="https://nftstars.shop/images/Logo3_new.png" alt="" />
         </div>

         <div  style={{display:"flex" , alignItems:"center",position:"relative",}}>
            <div style={{display:"flex",alignItems:"center",marginRight:"20px",gap:"8px"}}>

              <div className='notify-div'>
              <IoNotifications  style={{fontSize:"25px",margin:"0 10px",cursor:'pointer'}}/>
              <div id='freelancerNotify' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <Notifications/>
                </div>
              </div>

              
              <div className='project-div'>
              <FaFolderOpen  style={{fontSize:"25px",margin:"0 10px",cursor:'pointer',position:'relative'}}/>
                <div id='freelanceProject' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <RecentProject detail={detail}/>
                </div>
              </div>
             
              <div className='message-div'>
              <MdChatBubble  style={{fontSize:"25px",cursor:'pointer'}}/>
              <div id='freelanceMessage' style={{position:'absolute'}}>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",height:"38px",width:"38px",color:"white"}}/>
                   <Messages chatUser={chatUser}/>
                </div>
              </div>
            </div>


            <button className='projectButton' onClick={()=>history.push('/post-project')} style={{background:'#C40D6C',color:'white',height:'40px',padding:'5px 20px',borderRadius:'3px',border:'none',fontSize:'16px',fontWeight:'500',marginRight:'10px'}}>Post a Project</button>


            <div className='profile-div' style={{display:"flex",alignItems:"center"}}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" style={{cursor:"pointer"}} />
              <div style={{marginLeft:"6px",cursor:"pointer"}}>
                <h5 style={{margin:"0"}}>@ankit01</h5>
                <p style={{margin:"0"}}>₹ 0.00 INR</p>
              </div>
              
              <div id='freelancer-profile'>
                <TiArrowSortedUp style={{position:"absolute",top:"-21px",right:"38px",height:"38px",width:"38px",color:"white"}}/>
                <h5>Account</h5>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>View Profile</div>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Membership</div>
                <div onClick={()=>history.push(`/dashboard`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Dashboard</div>
                <div onClick={()=>history.push(`/freelancer/myProjects`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>My Projects</div>
                <div onClick={()=>history.push(`/freelancer/insightbids`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Insight Bids</div>
                <div onClick={()=>history.push(`/freelancer/settings/profile`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Setting</div>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Balance ₹500</div>
                <div onClick={()=>history.push(`/u/ankit`)} style={{color:"black",marginTop:"5px",cursor:"pointer"}}>Logout</div>
              </div>
            </div>

         </div>
    </div>
      
    </div>
  )
}

export default FreelancerNavbar

import { Box, Card, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import { FaSearch } from 'react-icons/fa'

const PastWork = () => {
    const content = [{},{},{},{},{}]
    return (
      <div>
          <div style={styles.container}>
        <FaSearch style={styles.icon} />
        <input
          type="text"
          placeholder="Search Projects"
          style={styles.input}
        />
      </div>
  
      <Card style={{width:'100%',height:'auto',marginTop:'20px'}}>
      <div style={{ width: '100%', overflowX: 'auto' }}>
                          <table style={{ width: '100%', minWidth: "870px" }}>
                              <thead style={{width:'100%'}}>
                                  <tr>
                                      
                                      <th align='center' style={{ padding: '10px', width: "250px", }}>Project Name</th>
                                      <th align='center' style={{ padding: '10px',textAlign:'center' }}>Client</th>
                                      <th align='center' style={{ padding: '10px',textAlign:'center' }}>Awaeded Bid</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Deadline</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Milestone</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Actions</th>
                                     
                                  </tr>
                              </thead>
                              <tbody>
  
                                  {content && content.map((item,index)=>(
                                  <tr style={{borderTop:'0.3px solid rgba(0,0,0,0.1)'}}>
                                   
                                      <td  style={{ padding: '10px' }}>XYZ PRojects</td>
                                      <td  style={{ padding: '10px',textAlign:'center' }}>41</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>$15.00 USD</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>$8.00 USD / hour</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>27 minutes ago</td>
                                      <td style={{ padding: '10px',textAlign:'center',color:'green' }}>Complete</td>
                                  
                                      
                                  </tr>
  
                                  ))}
                              </tbody>
                          </table>
                      </div>
      </Card>
      </div>
    )
  }
  
  const styles = {
      container: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '20px',
        padding: '8px 12px ',
        width: '90%',
        backgroundColor: '#f9f9f9',
        
      },
      icon: {
        color: '#007bff', // Icon color (blue in this case)
        marginRight: '8px',
      },
      input: {
        border: 'none',
        outline: 'none',
        fontSize: '16px',
        width: '100%',
        backgroundColor: 'transparent',
      },
    };

export default PastWork

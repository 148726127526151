import React, { useEffect, useState } from 'react'
import { IoMdMore } from "react-icons/io";
import { Grid } from '@material-ui/core';
import VtubeCard from './VtubeCard';
import ReelSlider from '../ReelSlider';
import Cookies from 'js-cookie';
import Axios from 'axios';

const VtubeContents = ({open,setOpen}) => {
     const [cookieData, setCookieData] = useState({});
     const [videoData,setVideoData] = useState()
     console.log(videoData,'videodata')

     const getAllVideo = async() => {

          console.log(cookieData ,'cookiedata')
          
          if(Object.keys(cookieData).length > 0 ){
        
            let data = {
              'api_key':cookieData?.api_key,
              'user_id':cookieData?.id,
            
            }
          try {
            let response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_all_videos`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
            if(response){
              setVideoData(response?.data?.response?.videos)
              
              return true
            }
          } catch (error) {
            console.log(error)
          }
        }
         
        }

        useEffect(()=>{
          getAllVideo()
        },[cookieData])

     useEffect(() => {

          let data = Cookies.getJSON('name');
          setCookieData(data?.user_data)
         
        
        }, []);
  return (

    
     <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:"70px"}}>

  <div id='ReelSlider_div' style={!open ?{ width:'94vw'} :{width:'82vw'}}>
      <ReelSlider/>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
      <Grid container
      style={{padding:"10px"}}
       spacing={2}
       alignItems='stretch'
       direction='row'
       justify='left'>

        {videoData && videoData.map((item,index)=>(

        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
             <VtubeCard cardData={item}/>
        </Grid>
        ))}

     
        
      </Grid>

    </div>

   
    </div>
  )
}

export default VtubeContents

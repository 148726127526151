import { Card } from '@material-ui/core';
import React from 'react'
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PostProject = () => {
    const history = useHistory()
  return (
    <div>
         <div style={styles.container}>
      <FaSearch style={styles.icon} />
      <input
        type="text"
        placeholder="Search Projects"
        style={styles.input}
      />
    </div>


    <Card style={{width:'100%',height:'300px',marginTop:'20px',}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'100%'}}>
        <div style={{fontWeight:'600'}}>Create a New Project</div>
        <div>Millions of talented freelancers are ready to help you do amazing things.</div>

        <button onClick={()=>history.push('/post-project')} style={{background:'#C40D6C',color:'white',height:'50px',padding:'5px 20px',borderRadius:'3px',marginTop:'40px',border:'none',fontSize:'20px',fontWeight:'500'}}>Post a Project</button>
      </div>
    </Card>
    </div>
  )
}


const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: '20px',
      padding: '8px 12px ',
      width: '90%',
      backgroundColor: '#f9f9f9',
      
    },
    icon: {
      color: '#007bff', // Icon color (blue in this case)
      marginRight: '8px',
    },
    input: {
      border: 'none',
      outline: 'none',
      fontSize: '16px',
      width: '100%',
      backgroundColor: 'transparent',
    },
  };

export default PostProject

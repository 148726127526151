import React from 'react'
import { TfiFolder } from 'react-icons/tfi'

const Notifications = () => {
  return (
    <div>
           <div style={{padding:'20px',width:'100%'}}>
        <div style={{fontSize:'18px',fontWeight:'500',color:'black',textAlign:'left'}}>Notifications</div>

        <div style={{display:'flex',gap:'20px',marginTop:'10px'}}>
          <div style={{height:'40px',width:'40px',border:'1px solid black'}}></div>
          <div>
            <div style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'310px'}}>@Rupesh7759 posted in Freelancer</div>
            <div style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'310px',fontSize:'20px'}}>Reacr native , tailwind , React , HTML , JS sjdnnkjsnkd sndkjfhhskhd</div>
            <div style={{display:'flex',justifyContent:'space-between',maxWidth:'310px'}}>
               
                <div>2 minutes ago</div>
            </div>
          </div>
       </div>
    </div>
    </div>
  )
}

export default Notifications

import { Card, CardContent } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaFlag } from 'react-icons/fa'
import toastr from 'reactjs-toastr/lib/react-toast'

const Pyments = ({cookieData,detail,details}) => {

    const [reward,setReward] = useState()
    const[describe,setDescribe] = useState()
    const[amount,setAmount] = useState()
    const [milestoneData,setMilestoneData] = useState()
    const [load,setLoad] = useState(false);

    const getPayment = async() => {
        if(Object.keys(cookieData).length > 0 ){
        const data = {
          "project_id":detail?.id,
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_payment_by_project`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
              setReward(response?.data?.data)
              console.log(response,'response')
              return true
           }
          }
      }


      const createMilestone = async() => {
        if(Object.keys(cookieData).length > 0 ){
        const data = {
          "project_id":detail?.id,
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "description":describe,
          "amount":amount,
          "status":"pending"
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/create_milestone`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
            setAmount('')
               setDescribe('')
               toastr.success('Your milestone has created!')
               setLoad(!load)
              console.log(response,'response')
              return true
           }
          }
      }

      const getMilestoneData = async() => {
        if(Object.keys(cookieData).length > 0 ){
        const data = {
          "project_id":detail?.id,
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/get_milestones_by_project`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
              setMilestoneData(response?.data?.data)
            
              console.log(response,'response')
              return true
           }
          }
      }

      console.log(milestoneData,'milestone')

      useEffect(()=>{
         getPayment();
         getMilestoneData();
      },[details,load])
    
  return (
    <div style={{width:'100%',display:'flex',marginTop:'20px',gap:'20px'}}>
       <div style={{width:'70%'}}>
        <Card style={{height:'auto'}}>
            <CardContent>
                <div style={{fontWeight:'600',fontSize:'18px'}}>Payment Summary</div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'20px'}}>
                    <div>
                        <div style={{fontWeight:'500'}}>Requested</div>
                        <div style={{marginTop:'10px'}}>${reward && reward[0]?.amount} USD</div>
                    </div>

                    <div>
                        <div style={{fontWeight:'500'}}>In Progress</div>
                        <div style={{marginTop:'10px'}}>$00.00 USD</div>
                    </div>

                    <div>
                        <div style={{fontWeight:'500'}}>Released to freelancer</div>
                        <div style={{marginTop:'10px'}}>$00.00 USD</div>
                    </div>
                </div>
            </CardContent>
        </Card>

        <Card style={{height:'auto',marginTop:'20px'}}>
            <CardContent>
                <div style={{fontWeight:'600',fontSize:'18px'}}>Milestone Payment</div>
{
    milestoneData?.length > 0 &&
                <div style={{marginTop:'20px',width:'100%'}}>
                    
                        <div style={{fontWeight:'500'}}>Created Milestone</div>
                        <table style={{width:'100%',marginTop:'20px'}}>
                        <thead>
                            <tr>
                             <th style={{fontWeight:'500'}}>Date</th>
                             <th style={{fontWeight:'500'}}>Description</th>
                             <th style={{fontWeight:'500'}}>Status</th>
                             <th style={{fontWeight:'500'}}>Amount</th>
                            </tr>
                        </thead>
{milestoneData && milestoneData.map((item,index)=>(
                        <tbody key={index}>
                            <tr>
                             <td >Nov 12, 2024</td>
                             <td >{item?.description}</td>
                             <td >{item?.status}</td>
                             <td>${item?.amount} USD</td>
                             <td>
                                <button style={{border:'1px solid black', borderRadius:'3px',padding:'3px 10px'}}>Release milestone</button>
                             </td>
                            </tr>
                        </tbody>
))}
                        </table>
                   
                </div>
}
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'20px'}}>
                    <div>
                        <div style={{fontWeight:'500'}}>Requeste Milestone</div>
                        <div>Submitting a milestone request will prompt @rupesh to fund a Milestone Payment upfront.</div>
                    </div>
                </div>

                <div className='column2' style={{display:"flex", justifyContent:"space-between",marginTop:'20px'}}>

                <div style={{ marginBottom: "20px",width:"46%"  }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>Description</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <input  aria-multiline
                            value={describe}
                            onChange={(e) => setDescribe(e.target.value)}
                            placeholder='Describe your milestone'
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        />
                        </div>
                    </div>

                    <div style={{ marginBottom: "20px",width:"46%" }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>Amount</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <span style={{ marginRight: "8px" }}>$</span>
                        <input type="number"
                            value={amount}
                            placeholder='Amount'
                            onChange={(e) => setAmount(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>USD</span>
                        </div>
                    </div>

                    

                </div>

                <div style={{display:'flex',justifyContent:'end',alignItems:'center',gap:'20px'}}>
                    <button style={{border:'1px solid black',padding:'2px 10px',borderRadius:'3px'}}>Cancel</button>
                    <button onClick={createMilestone} style={{border:'1px solid black',padding:'2px 10px',borderRadius:'3px'}}>Create Milestone</button>
                </div>
            </CardContent>
        </Card>
       </div>

       <div style={{width:'25%'}}>
         <Card style={{height:'auto'}}>
            <CardContent>
                 <div>The Client</div>
                 <div style={{display:'flex',gap:'20px',marginTop:'20px'}}>
                    <div style={{width:'50px',height:'50px',border:'1px solid black',borderRadius:'3px',}}></div>
                      <div>
                        <div style={{display:'flex',alignItems:'center',gap:'10px',fontWeight:'500'}}><FaFlag/>Rupesh M.</div>
                        <div>@rupesh770</div>
                      </div>
                 </div>

                 <button style={{border:'1px solid black',borderRadius:'3px',width:'100%',background:'transparent',marginTop:'20px'}}>Chat</button>
            </CardContent>
         </Card>
       </div>
    </div>
  )
}

export default Pyments

import React, { useEffect, useState } from 'react';

import Header from './header';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toastr from 'reactjs-toastr';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function Login() {
  const history = useHistory();
  const location = useLocation()
  console.log(location,'loctiom')
  const code = location?.search?.split('=')[1];
  console.log(code,'code')
  const [isLoading, setIsLoading] = useState(false);
  const [cookieData, setCookieData] = useState({});
  const[channelData,setChannelData] = useState()
  const [load,setLoad] = useState(false);

  
  let data = Cookies.getJSON('name');

 

 
  const initialValues = {
    email: '',
    password: '',
    email_auth_code:code ? code : ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const headers = {
    'Content-Type': 'text/plain',
  };

  const handleSubmit = async (values) => {
    setIsLoading(true)
 
    await axios.post(`${process.env.REACT_APP_URL}/api/users/login`, values, { headers })
    .then(response => {

        if (response.data.code === true) {
         
            toastr.success('Sucess', { displayDuration: 3000 })
            Cookies.set('name', response.data);
            setIsLoading(false)

            if (response?.data?.user_data?.is_enable_google_auth_code === '0' || Object.keys(cookieData).length > 0) {
               setLoad(!load)
                getChannelData();
                window.location.hash = '/dashboard'
            }
            else if (response?.data?.user_data?.is_enable_google_auth_code === '1') {
                // window.location.reload(true);
                window.location.hash = '/twofa'
            }

        }
        else if (response?.data?.code === false) {
            toastr.error(response.data.message, { displayDuration: 3000 })            
        }
    })

    .catch(err => {
        console.log(err)
    })
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
     if(code){
      toastr.success('Your email successfully verified', { displayDuration: 3000 })
      getChannelData();
     }

  },[code])

  const getChannelData = async() => {
    let data = { 
        "api_key":cookieData?.api_key
        
        }
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/users/get_channels`,
        data,
        {
          headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          }
         });
         if(response.data.code === true){
           setChannelData(response?.data?.response[0])
           Cookies.set('channel', response?.data?.response[0])
          
         }
}

useEffect(()=>{
  getChannelData();
},[cookieData,load])

useEffect(() => {

  let data = Cookies.getJSON('name');
  setCookieData(data?.user_data) 
 
}, [load]);


  return (
    <div style={{  height: '100%', overflow: 'hidden',backgroundColor:"#323132", }}>
      <div>
        {/* <Header /> */}
      </div >
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '50px', }}>
        <div className="loginContentDiv" style={{ width: '90%', display: 'flex', marginTop: '100px' }}>
          <div className="loginContentDivVideo">
            <video autoPlay loop muted className="loginContentDivVideo2" style={{ width: '100%' }}>
              <source src="/login-video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="box_shadow loginpageDiv" style={{ marginTop: '40px', width: '' }}>
            <div className="login-div">
              <div className="head">
                <h3 className="purple oR m0">Join collective Transformation</h3>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="body">
                  <div className="input-box">
                    <input
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="email"
                      className="input11"
                      type="email"
                    />
                    <span style={{ position: 'absolute' }}>
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                  <span className="errorMessage_signup">{formik.touched.email && formik.errors.email}</span>
                  <div className="input-box">
                    <input
                      placeholder="Password"
                      type="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="password"
                      className="input11"
                    />
                    <span style={{ position: 'absolute' }}>
                      <i className="fa fa-key"></i>
                    </span>
                  </div>
                  <span className="errorMessage_signup">{formik.touched.password && formik.errors.password}</span>
                </div>
                <div className="foot">
                  <Link to="/forgetpassword" className="forgot pull-left">
                    <div style={{ fontSize: '110%' }}>Forgot your password?</div>
                  </Link>
                  <button type="submit" className="btn btn-gradient W100 pull-right" disabled={!formik.isValid}>
                  {isLoading ?(<div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                        <CircularProgressBar/>
                                        <div>Login</div>
                                    </div>  ) : 'Login'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      <div style={{backgroundColor:"#0E0E10",display:"flex",justifyContent:"center",minHeight:"100vh",alignItems:"center"}}>
<form style={{ maxWidth:"500px",backgroundColor:"#18181B",color:"#fff",padding:"30px",width:"100%"}}  onSubmit={formik.handleSubmit}>
<div style={{ display:"flex", alignItems:"center",justifyContent:"center"}}>
   <Link  to="/" >
                              {/* <img className="img-responsive" alt="Notjing" src="logo_freedom_white.png" /> */}
                              <img className="img-responsive" alt="Notjing" src="/new-logo2.png"style={{width:"100px",height:"70px",borderRadius:"100%"}} />
                              </Link>
                <h3 className="purple oR m0">Log in to Victus</h3>
              </div>
              <div  style={{
                marginTop:"20px"
              }}>
                  <div className='form-container'>
                    <strong>Username</strong>
                    <input
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="email"
                     className='input-field'
                     style={{
                      background:"#18181B !important"
                     }}
                      type="email"
                    />
                    
                  </div>
                  <span className="errorMessage_signup">{formik.touched.email && formik.errors.email}</span>
                  <div className='form-container'>
                    <strong>Password</strong>
                    <input
                      placeholder="Password"
                      type="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="password"
                       className='input-field'
                    />
                    {/* <span style={{ position: 'absolute' }}>
                      <i className="fa fa-key"></i>
                    </span> */}
                  </div>
                  <span className="errorMessage_signup">{formik.touched.password && formik.errors.password}</span>
                </div>
                <div  style={{
                  display:"flex",
                  flexDirection:"column"
                }}>
                  <span style={{
                      color:"#AE8A3D"
                  }}>

                  <Link to="/forgetpassword" >
                   Forgot your password?
                  </Link>
                  </span>
                  <button type="submit" className="btn btn-gradient W100 " style={{
                    marginTop:"20px"
                  }} disabled={!formik.isValid}>
                  {isLoading ?(<div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                        <CircularProgressBar/>
                                        <div>Login</div>
                                    </div>  ) : 'Login'}
                  </button>

                  <p style={{
                                  textAlign:"center",
                                  fontSize:"20px",
                                  color:"#fff"
                                  
                                }}>Don't have an account ? <Link to="/register" style={{
                    color:"#AE8A3D"
                  }}>Register</Link></p>
                </div>
</form>
      </div>
      <div style={{ textAlign:"center",padding:"10px",color:"white",}}>Audentis fortuna iuvat</div>
    </div>
  );
}


const CircularProgressBar = () => {
  return (
    <div className="circular-progress">
      <div className="circular-progress-bar"></div>
    </div>
  );
};

export default Login;

import { Box, Button, Card, CardContent, Divider, TextField, Typography } from '@material-ui/core'
import React from 'react'

const Payment = () => {
  return (
    <div>
       <Card>
        <CardContent>
        <Typography variant="h5" gutterBottom style={{fontWeight:'600'}}>
              Payment & Financials
            </Typography>

            <Divider style={{margin:'20px 0px'}} />

            <Typography variant="h6" gutterBottom style={{fontWeight:'600'}}>
              Payment Method
            </Typography>

            <button style={{height:'35px',border:'1px solid black',borderRadius:'3px',background:'transparent',marginTop:'10px'}}>+ Add Payment Method</button>

            <Divider style={{margin:'20px 0px'}} />

            <Typography variant="h6" gutterBottom style={{fontWeight:'600'}}>
              Tax Information
            </Typography>

          <Box style={{display:'flex',flexDirection:'column'}}>
            <Typography style={{fontWeight:'500',color:'black'}}>Gst Identification Number</Typography>
            <TextField variant='outlined' placeholder='GSTIN' className='paymentField' />
            <Button variant='contained' size='small' style={{background:'blue',marginTop:'10px',width:'120px',color:'white'}}>Save GSTIN</Button>

          </Box>

          <Typography style={{fontWeight:'500',color:'black',marginTop:'20px'}}>TDS Identification Number</Typography>
          <div>TDS (Tax Deducted at Source) is a form of income tax introduced by the India government as part of the Income Tax Act. If you are based in India, you are required to provide us with your PAN number. If you don't provide this number, we are required to withhold 5% of all your earnings.</div>
<Box style={{display:'flex',flexDirection:'column'}}>

          <TextField variant='outlined' placeholder='PAN' className='paymentField'/>
            <Button variant='contained' size='small' style={{background:'blue',marginTop:'10px',width:'150px',color:'white'}}>Save TDS Identity</Button>
</Box>

<Divider style={{margin:'20px 0px'}} />
<Typography variant="h6" gutterBottom style={{fontWeight:'600'}}>
              Finance Setting
            </Typography>
            <Typography style={{fontWeight:'500',color:'black'}}>My Currency</Typography>
            <Box style={{display:'flex',flexDirection:'column'}}>

          <TextField variant='outlined' placeholder='INR' className='paymentField'/>
            <Button variant='contained' size='small' style={{background:'blue',marginTop:'10px',width:'100px',color:'white'}}>Save</Button>
</Box>

        </CardContent>
      </Card>
    </div>
  )
}

export default Payment

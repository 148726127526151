import { Box, Button, Card, CardContent, Divider, Step, StepLabel, Stepper, Typography } from '@material-ui/core'
import React from 'react'

const TrustVerification = () => {

    const steps = [
        '',
        'Low',
        'Good',
        'Excellent'
      ];
  return (
    <div>
        <Card>
        <CardContent>
        <Typography variant="h5" gutterBottom style={{fontWeight:'600'}}>
              Trust & Verification
            </Typography>

            <Divider style={{margin:'20px 0px'}} />
            <Box sx={{ width: '100%' }}>
      <Stepper activeStep={1} alternativeLabel style={{width:'100%'}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>


    <Box style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
      <Typography>CURRENT TRUST SCORE : 5</Typography>
      <Typography>STRENGTH : LOW</Typography>
    </Box>
    <Divider style={{margin:'20px 0px'}} />
      <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
        <div>
        <Typography variant='h6'>Email Address</Typography>
        <Typography className='trustText2' style={{color:'green'}}>VERIFIED</Typography>
        </div>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <Typography className='trustText' style={{color:'green'}}>VERIFIED</Typography>
          <Button variant='contained' size='small' style={{background:'blue',borderRadius:'15px',color:'white'}}>5 points</Button>
        </Box>
      </Box>
    <Divider style={{margin:'20px 0px'}} />

    
      <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
        <div>
        <Typography variant='h6'>Phone Number</Typography>
        <button className='trustText2' style={{border:'1px solid black',borderRadius:'3px',height:'35px'}}>Add phone Number</button>
        </div>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
         <button className='trustText' style={{border:'1px solid black',borderRadius:'3px',height:'35px'}}>Add phone Number</button>
          <Button variant='contained' size='small' style={{background:'blue',borderRadius:'15px',color:'white'}}>10 points</Button>
        </Box>
      </Box>
    <Divider style={{margin:'20px 0px'}} />


    
      <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
        <div>
        <Typography variant='h6'>Facebook</Typography>
        <Typography className='trustText2' style={{color:'green'}}>VERIFIED</Typography>
        </div>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
        <Typography className='trustText' style={{color:'green'}}>VERIFIED</Typography>
          <Button variant='contained' size='small' style={{background:'blue',borderRadius:'15px',color:'white'}}>20 points</Button>
        </Box>
      </Box>
    <Divider style={{margin:'20px 0px'}} />



      <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
        <Typography variant='h6'>Linkedin</Typography>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <Typography style={{color:'green'}}>VERIFIED</Typography>
          <Button variant='contained' size='small' style={{background:'blue',borderRadius:'15px',color:'white'}}>5 points</Button>
        </Box>
      </Box>
    <Divider style={{margin:'20px 0px'}} />
        </CardContent>
      </Card>
    </div>
  )
}

export default TrustVerification

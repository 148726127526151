import React, { useEffect, useRef, useState } from 'react'
import { IoCloseSharp } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { RxVideo } from "react-icons/rx";
import { AiFillLike } from "react-icons/ai";
import { MdModeComment } from "react-icons/md";
import { PiShareFatFill } from "react-icons/pi";
import Emoji from '../contents/Emoji';
import { RiSendPlane2Fill } from "react-icons/ri";
import PhoneReel from './PhoneReel';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useSwipeable } from 'react-swipeable';
import { Avatar, Drawer } from '@material-ui/core';
import CommentDrawer from '../contents/CommentDrawer';
import InfiniteScroll from 'react-infinite-scroll-component';
import VtubeNavbar from './vtube/VtubeNavbar';
import VtubeSidebar from './vtube/VtubeSidebar';
import VtubeAppBar from './vtube/VtubeAppBar';




const Dialog = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="dialog-overlay">
        <div className="dialog-content" style={{padding:'20px'}}>
          {children}
        </div>
      </div>
    );
  };

const ReelPage = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [cookieData, setCookieData] = useState({});
  const[storyData,setStoryData] = useState()
  const[reelTimeline,setReelTimeline] = useState()
  const [imgPreview,setImgPreview] = useState()
const [imgFile,setImgFile] = useState()
const[fileType,setFileType] = useState()
const[message,setMessage] = useState('')
const [commentBox,setCommentBox] = useState(false)
const[commentText,setCommentText] = useState('')
const[commentReplyText,setCommentReplyText] = useState('')
const[reply,setReply] = useState(false);
const[open,setOpen] = useState(false);
const [sideopen,setSideOpen] = useState(false)
const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
const[comments,setComments] = useState();
const [replyStates, setReplyStates] = useState({});
  const location = useLocation()
  const [apiCalled, setApiCalled] = useState(false);
  const [commentLoad,setCommentLoad] = useState(false)
  const [videoWatched, setVideoWatched] = useState(false);
  // console.log(location,'loctiom')
  const reelId = location?.search?.split('=')[1];
  let reelId2 = (reelId ?  storyData && storyData?.reel_id : reelTimeline && reelTimeline[currentStoryIndex]?.reel_id)
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  
  
  // console.log(userId,'code')
 
  // console.log(cookieData,'cookiedata')
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const toggleReply = (index) => {
    setReplyStates(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];
    let file_type = '';
    if (image_as_files.type.indexOf('image') === 0) {
        file_type = 'image';
    } else {
        file_type = 'video';
    }
     setImgPreview(image_as_base64)
     setImgFile(image_as_files)
     setFileType(image_as_base64)
  
   
}

   
      
    
    
      const handleNext = (id) => {

        console.log('next')
        if(id){
          window.location.hash = `/reel/?reelid=${id}`;
        }
        if (currentStoryIndex < reelTimeline?.length - 1) {
          setCurrentStoryIndex(currentStoryIndex + 1);
        //   setProgress(0); // Reset progress when manually changing stories
        }
      };
    
      const handlePrev = (id) => {
        if(id){
          window.location.hash = `/reel/?reelid=${id}`;
        }
        if (currentStoryIndex > 0) {
          setCurrentStoryIndex(currentStoryIndex - 1);
        //   setProgress(0); // Reset progress when manually changing stories
        }
      };

    //   let prgLength = 90/storyData?.length
      

      const getReelDataById = async(id) => {

     
        
        if(Object.keys(cookieData).length > 0 ){
      
          let data = {
            'api_key':cookieData?.api_key,
            'reel_id':reelId,
            'user_id':cookieData?.id
          }

        
        try {
          let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_by_id`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
          if(response){
            setStoryData(response?.data?.reel)
          }
        } catch (error) {
          console.log(error)
        }
      }
       
      }

      console.log(storyData,'reelid')


      const handleGetComments = async () => {
        try {
          let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/comments_by_reel_id`,{
            'api_key':cookieData?.api_key,
            'reel_id':reelId2,
            'page':'1',
            'user_id':cookieData?.id
          },
       
       {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       }
      
      );
      if(response){
        console.log(response,'response of comments')
        setComments(response?.data?.comments)
      }
        } catch (error) {
          console.log(error)
        }
      }

      console.log(comments,'comments');
      console.log(reelId,'id')
const handleCreateReel = async() =>{

    try {
      if(Object.keys(cookieData).length > 0 ){
        let formdata = new FormData()
        formdata.append('file',imgFile)
        formdata.append('file_type',fileType)
        formdata.append('api_key',cookieData?.api_key)
        formdata.append('user_id',cookieData?.id)
        formdata.append('message',message)
        console.log(formdata,'formdata')
       let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/add_reel`,
       formdata,
       {
        headers:{
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
        }
       }
      
      );
      if(response){
        setIsDialogOpen(false)
        getReelTimeline()
      }
  
    
      }
  
    } catch (error) {
      console.log(error,'error')
    }
  
  
  }

  const createComment = async() => {

       
        
    if(Object.keys(cookieData).length > 0 ){
        setCommentLoad(true)
      let data = {
        'api_key':cookieData?.api_key,
        'user_id':cookieData?.id,
        'comment':commentText,
        'reel_id':reelId2,
        'file_type':''
      }
    try {
      let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_comment`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
      if(response){
        // console.log(response,'comment response')
        setCommentText('')
        setCommentLoad(false)
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
   
  }



  const createCommentReply = async(comment_id) => {

       
        
    if(Object.keys(cookieData).length > 0 ){
        
      let data = {
        'api_key':cookieData?.api_key,
        'user_id':cookieData?.id,
        'reply':commentReplyText,
        'reel_id':reelId2,
        'reel_comment_id':comment_id,
        'file_type':''
      }
    try {
      let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_comment_reply`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
      if(response){
        // console.log(response,'comment response')
        setCommentReplyText('')
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
   
  }


      const getReelTimeline = async() => {

       
        
        if(Object.keys(cookieData).length > 0 ){
            
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id,
            'page':'1',
            'limit':'100'
          }
        try {
          let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_timeline`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
          if(response){
            setReelTimeline(response?.data?.reels)
            
            return true
          }
        } catch (error) {
          console.log(error)
        }
      }
       
      }

      useEffect(() => {

        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
        if(Object.keys(cookieData).length > 0 ){
          
          getReelTimeline()
        }
        
      }, []);
      let commentLike = (reelId ?  storyData && storyData?.comment_liked : reelTimeline && reelTimeline[currentStoryIndex]?.comment_liked)

      let like = (reelId ?  storyData && storyData?.reel_liked : reelTimeline && reelTimeline[currentStoryIndex]?.reel_liked);
      const[load,setLoad] = useState(false);

      // console.log(storyData,'storyData')
      // console.log(storyTimeline,'timeline')
      console.log(like,'like')
      console.log(load,'load')

      const handleReelLike = async() => {
        if(Object.keys(cookieData).length > 0 ){
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id,
            'reel_id':reelId2,
           
          }
        try {
          
            setLoad(true)
            let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_like`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response){
              setLoad(false);
               like = true
              return true
            }
            
          
          
        
        } catch (error) {
          console.log(error)
        }
      }
      }


      const handleReelCommentLike = async(comment_id) => {
        if(Object.keys(cookieData).length > 0 ){
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id,
            'reel_comment_id':comment_id,
           
          }
        try {
          
            setLoad(true)
            let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/reel_comment_like`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response){
              setLoad(false);
               commentLike = true
              return true
            }
        } catch (error) {
          console.log(error)
        }
      }
      }


      useEffect(() => {
        console.log('watch reel')
        const handleTimeUpdate = () => {
          if (videoRef?.current) {
            const percentageWatched = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    
            if (percentageWatched >= 90 && !videoWatched) {
              setVideoWatched(true);
            }
          }
        };
    
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }
    
        return () => {
          if (videoElement) {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
          }
        };
      }, [videoWatched,videoRef]);


      console.log(videoWatched,'videowatch')

      useEffect(() => {
        // console.log('videroref2 awatch')
        const handleTimeUpdate = () => {
          
          if (videoRef2?.current) {
            const percentageWatched = (videoRef2.current.currentTime / videoRef2.current.duration) * 100;
              // console.log(percentageWatched,'percentage')
            if (percentageWatched >= 90 && !videoWatched ) {
              setVideoWatched(true);
            }else if(percentageWatched < 10)
            {
              setVideoWatched(false)
            }
          }
        };
    
        const videoElement = videoRef2.current;
        if (videoElement) {
          videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }
    
        return () => {
          if (videoElement) {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
          }
        };
      }, [videoWatched,videoRef2]);

      useEffect(() => {
        if (videoWatched) {
          sendWatchData();
          setApiCalled(true); // Set the flag to prevent multiple API calls
        }
      }, [videoWatched, apiCalled]);


      const sendWatchData = async() => {
        if(Object.keys(cookieData).length > 0 ){
          let data = {
            'api_key':cookieData?.api_key,
            'user_id':cookieData?.id,
            'reel_id':reelId2,
           
          }
        try {
          
          
            let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/record_reel_view`,
            data,
            {
              headers:{
                'Content-Type': 'text/plain',
                'Accept':'application/json, text/plain, */*'
              }
             });
             if(response){
            
              
              return true
            }
        } catch (error) {
          console.log(error)
        }
      }
      }

      

      useEffect(()=>{
        // getUserStoryData()
        getReelTimeline()
        handleGetComments();
        getReelDataById();
      },[cookieData,commentText,like])

      useEffect(()=>{
        handleGetComments();
        getReelDataById();
      },[reelId,commentText,currentStoryIndex,like,commentReplyText,load])

      useEffect(()=>{
        getReelTimeline();
        getReelDataById();
      },[like,load,commentLike])
      

      useEffect(()=>{
        handleGetComments();
        getReelDataById();
      },[commentLoad])

    //   console.log(reelTimeline,'reel')

    //   const filteredMyStory = reelTimeline?.filter(item => cookieData?.id.includes(item?.user_id));

     
    //     const filteredUserStory = reelTimeline?.filter(item => item?.user_id !== cookieData?.id);
        // console.log(filteredUserStory,'story')
 
        const handleDiscardReel = () => {
            setImgPreview('')
            setFileType('')
          }

          const swipeHandlers = useSwipeable({
           
            onSwipedUp: ()=>handleNext(reelTimeline && reelTimeline[currentStoryIndex]?.reel_id),  // Swiping left goes to the next story
            onSwipedDown: ()=>handlePrev(reelTimeline && reelTimeline[currentStoryIndex]?.reel_id), // Swiping right goes to the previous story
            preventDefaultTouchmoveEvent: true,
            trackMouse: true, // Optional: Allows swipe-like behavior with mouse drags
          });

          const [isPlaying, setIsPlaying] = useState(true);

          // Reference to the video element
          
        
          const handleVideoClick = () => {
            if (isPlaying) {
              console.log(videoRef)
              videoRef.current.pause();
              videoRef.current.muted = true; // Pause the video
              setIsPlaying(false); // Update state to indicate video is paused
            } else {
              videoRef.current.play(); // Play the video
              setIsPlaying(true); // Update state to indicate video is playing
            }
          };

          const handleVideoClick2 = () => {
            if (isPlaying) {
              // console.log(videoRef2)
              videoRef2.current.pause();
              videoRef.current.muted = true; // Pause the video
              setIsPlaying(false); // Update state to indicate video is paused
            } else {
              videoRef2.current.play(); // Play the video
              setIsPlaying(true); // Update state to indicate video is playing
            }
          };

          const handleLocation = () => {
           
              window.location.hash = '/vtube';
            
          }
          const [items, setItems] = useState([]); // Start with an empty array

          useEffect(() => {
            if (reelTimeline && reelTimeline.length > 0) {
              setItems(reelTimeline.slice(0, 2)); // Initialize items with the first 5 reels
            }
          }, [reelTimeline]);

          const fetchMoreData = () => {
            // Simulate fetching more data
            if (reelTimeline.length > items.length) {
              setTimeout(() => {
                setItems((prevItems) => [
                  ...prevItems,
                  ...reelTimeline.slice(prevItems.length, prevItems.length + 1),
                ]);
              }, 1500);
            }
          };

          console.log(reelTimeline,'reeltimeline')
          console.log(items,'items')

  return (
    <>
      
      <div className='reelSection'>
        <VtubeNavbar open={sideopen} setOpen={setSideOpen}/>

        <div style={{width:'25%',background:'white',height:'auto'}}>
       
      <VtubeSidebar open={sideopen} setOpen={setSideOpen}/>
     
      <VtubeAppBar />
    


        </div>
        <div style={{width:'60%',height:'100vh',background:'white',display:'flex',flexDirection:'column',justifyContent:'end',gap:'10px',alignItems:'center',paddingBottom:'20px'}}>
                <div style={{width:'580px',height:'87vh',borderRadius:'15px',display:'flex',justifyContent:'center',alignItems:'center',gap:'20px'}}>

                  {currentStoryIndex > 0 && <div onClick={()=>handlePrev(reelTimeline && reelTimeline[currentStoryIndex]?.reel_id)} style={{background:'gray',borderRadius:'50px',marginLeft:'20px',display:'flex',alignItems:'center',padding:'10px',cursor:'pointer'}}><IoIosArrowBack style={{fontSize:'30px',color:'white'}}/></div>}  
                


                <div className="story" style={{width:'360px',height:'85vh',borderRadius:'15px',position:'relative'}}>
                {storyData && storyData?.file_type === 'image/jpeg' ?(

                     <img src={reelId ?  storyData && storyData?.file : reelTimeline && reelTimeline[currentStoryIndex]?.file} alt="Story" style={{width:'360px',height:'87vh',borderRadius:'15px'}}/> 
                ):(
                    <video ref={videoRef2}  alt="Reuters" typeof='video/mp4' autoPlay loop  className="imgDiv2" style={{width:'360px',height:'87vh',objectFit:'cover',borderRadius:'15px'}} src={reelId ?  storyData && storyData?.file : reelTimeline && reelTimeline[currentStoryIndex]?.file} 
                    onClick={handleVideoClick2}/>
                )}   


       

        <div style={{display:'flex',alignItems:'center',gap:'15px',height:'70px',padding:'0px 10px',borderRadius:'10px',position:'absolute',top:'5px'}}>
                <div style={{height:'50px',width:'50px',border:'5px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src='https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?t=st=1712910396~exp=1712913996~hmac=87fc987f7809e106e86cfb13ffec013049e3add7e0387d7ecf637021789a4383&w=996' style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div>
                                          <div style={{color:'white'}}>{reelId ?  storyData && storyData?.full_name : reelTimeline && reelTimeline[currentStoryIndex]?.full_name}</div>
                                          <div style={{fontSize:'14px',color:'white'}}>{reelId ?  storyData && storyData?.message : reelTimeline && reelTimeline[currentStoryIndex]?.message}</div>
                                      </div>
                </div>

                <div style={{position:'absolute',display:'flex',flexDirection:'column',gap:'20px',alignItems:'center',bottom:'0px',right:'-70px'}}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                  <div className={like ? 'like2':'dislike2'}>
                   <AiFillLike onClick={handleReelLike}  style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                    <div style={{fontWeight:'500',fontSize:'18px',color:'white'}}>{reelId ?  storyData && storyData?.like_count : reelTimeline && reelTimeline[currentStoryIndex]?.like_count}</div>
                 </div>


                 <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                   <div className='dislike2' style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                   <MdModeComment onClick={()=>setCommentBox(!commentBox)} style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                   <div style={{fontWeight:'500',fontSize:'18px',color:'white'}}>{reelId ?  storyData && storyData?.comment_count : reelTimeline && reelTimeline[currentStoryIndex]?.comment_count}</div>
                   </div>

                   <div className='dislike2' style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                   <PiShareFatFill style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                </div>



       
      </div>

      <div onClick={()=>handleNext(reelTimeline && reelTimeline[currentStoryIndex]?.reel_id)} style={{background:'gray',borderRadius:'50px',marginLeft:'20px',display:'flex',alignItems:'center',padding:'10px',cursor:'pointer'}}><IoIosArrowForward style={{fontSize:'30px',color:'white'}}/></div>
                </div>

             
        </div>

       {commentBox && 
       <div style={{width:'35%',background:'white',height:'87vh',position:'relative',top:'80px',border:'1px solid rgba(0,0,0,0.2)',borderRadius:'10px'}}>
         <div style={{display:'flex',alignItems:'center',gap:'15px',height:'60px',padding:'0px 10px',borderRadius:'10px'}}>
                    <Avatar src=''/>
                                      <div>
                                          <div style={{color:'black',fontWeight:'500'}}>{reelTimeline && reelTimeline[currentStoryIndex]?.full_name}</div>
                                          <div style={{fontSize:'14px',color:'black'}}>public</div>
                                      </div>
                </div>
                <div style={{fontSize:'18px',color:'black',padding:'0px 10px'}}>{reelId ?  storyData && storyData?.message : reelTimeline && reelTimeline[currentStoryIndex]?.message}</div>
                <hr/>

<div style={{maxHeight:'500px',overflowY:'scroll',width:'100%',display:'flex',flexDirection:'column',gap:'10px'}}>
{comments && comments.map((item,index)=>(


               <div key={index}>
                <div style={{display:'flex',gap:'5px',paddingLeft:'10px'}}>
                <Avatar src=''/>
                                      <div style={{height:'auto',background:'rgba(0,0,0,0.1)',borderRadius:'15px',width:'auto',padding:'5px 10px'}}>
                                           <div style={{fontWeight:'500',color:'black'}}>{item?.full_name}</div>
                                           <div>{item?.comment}</div>
                                      </div>
                </div>

                <div style={{display:'flex',gap:'10px',marginLeft:'70px',flexDirection:'column'}}>

                  <div style={{display:'flex',gap:'10px'}}>
                   <div className={item?.comment_reel_liked ? 'like5':'dislike5'} onClick={()=>handleReelCommentLike(item?.comment_id)} style={{fontWeight:'500',cursor:'pointer',fontSize:'12px'}}>Like {item?.like_count}</div>
                   <div onClick={() => toggleReply(index)} style={{fontWeight:'500',color:'black',cursor:'pointer',fontSize:'12px'}}>Reply</div>
                   {item?.replies?.length > 0 &&
                   <div onClick={() => toggleReply(index)} style={{fontWeight:'500',color:'black',cursor:'pointer',fontSize:'14px'}}>
                    View all {item?.replies?.length} Replies</div>
                   }
                 </div>


                 {replyStates[index] && 

                 <div>

                  {item && item?.replies.map((reply,index)=>(
                 <div key={index} style={{display:'flex',gap:'5px',paddingLeft:'10px',marginTop:'10px'}}>
                     <Avatar src=''/>
                                      <div style={{height:'auto',background:'rgba(0,0,0,0.1)',borderRadius:'15px',width:'auto',padding:'5px 10px'}}>
                                           <div style={{fontWeight:'500',color:'black'}}>{reply?.full_name}</div>
                                           <div>{reply?.reply}</div>
                                      </div>
                </div>
                ))}
                </div>
}
                   {replyStates[index] &&  
                   <div style={{display:'flex',gap:'5px',paddingLeft:'10px',width:'100%',top:'0px'}}>
                              <Avatar src=''/>
                                      <div style={{height:'90px',background:'rgba(0,0,0,0.1)',borderRadius:'20px',width:'80%',padding:'10px'}}>
                                            <div>
                                              <input value={commentReplyText} onChange={(e)=>setCommentReplyText(e.target.value)} placeholder='Reply on comment...' style={{padding:'0px 10px',height:'40px'}}/>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                              <Emoji/>
                                              <RiSendPlane2Fill onClick={()=>createCommentReply(item?.comment_id)} style={{cursor:'pointer',fontSize:'22px'}}/>
                                            </div>
                                      </div>
                </div>}
                  
                </div>
                </div>

))}
</div>


                <div style={{display:'flex',gap:'5px',paddingLeft:'10px',position:'absolute',bottom:'10px',width:'100%'}}>
                <Avatar src=''/>
                                      <div style={{height:'90px',background:'rgba(0,0,0,0.1)',borderRadius:'20px',width:'80%',padding:'10px'}}>
                                            <div>
                                              <input onChange={(e)=>setCommentText(e.target.value)} value={commentText} placeholder='Write a comment...' style={{padding:'0px 10px',height:'40px'}}/>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                              <Emoji/>
                                              <RiSendPlane2Fill onClick={createComment} style={{cursor:'pointer',fontSize:'22px'}}/>
                                            </div>
                                      </div>
                </div>

      </div>
}
        
      </div>


      <Dialog isOpen={isDialogOpen} onClose={closeDialog}>
                          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'20px'}}>
                         <h4>Create Reel</h4>
                          <IoCloseSharp onClick={closeDialog} style={{fontSize:'25px',cursor:'pointer'}}/>
                          </div>
                         {
                          fileType ? (
                            <>
                            <div style={{width:'100%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',display:'flex',alignItems:'center',margin:'20px'}}>
                                <div style={{height:'278px',width:'100%',borderRadius:'15px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                     {/* <img src={imgPreview} style={{height:'278px',width:'160px',borderRadius:'15px'}}/>
                                      */}
                                       <video alt="Reuters" typeof='video/mp4' autoPlay loop muted className="imgDiv2" style={{height:'278px',objectFit:'cover',borderRadius:'15px'}} src={fileType} />
                                </div>
                            </div>

                            <div style={{marginTop:'20px'}}>
                              <div>About or Tagline</div>
                                <input onChange={(e)=>setMessage(e.target.value)} type='text'placeholder='write something.....' style={{padding:'0px 10px',border:'1px solid black',height:'40px',borderRadius:'5px'}}/>
                            </div>
                            </>
                          ):(
                            <div style={{width:'100%',height:'280px',border:'1px solid rgba(0,0,0,0.4)',borderRadius:'10px',marginTop:'30px',display:'flex',alignItems:'center'}}>
                            <label className="fileContainer" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%'}}>
                               <img src='https://png.pngtree.com/png-vector/20191129/ourlarge/pngtree-image-upload-icon-photo-upload-icon-png-image_2047546.jpg' style={{height:'80px',width:'100px'}}/>
                                <div style={{fontSize:'24px',fontWeight:'500',color:'black'}}>Add Videos</div>
                                  <div>or drag and drop</div>
                                 <input type="file" id="image_text" accept=".jpg,.jpeg,.png,.mp4"  onChange={handleImagePreview}/>
                                   </label>
                            </div>
                          )
                         }

                        

                         {
                          fileType ? (
                         <div style={{display:'flex',gap:'10px'}}>
                         <button onClick={handleDiscardReel} style={{width:'100%',height:'40px',border:'none',borderRadius:'10px',marginTop:'20px'}}>Discard</button>
                         <button onClick={handleCreateReel} style={{width:'100%',height:'40px',background:'blue',border:'none',borderRadius:'10px',marginTop:'20px'}}>Create Reel</button>

                         </div>
                          ):("")}
                    </Dialog>


     
          
                      <InfiniteScroll 
                      next={fetchMoreData}
                      dataLength={items?.length}
                      hasMore={items?.length < reelTimeline?.length}
                      loader={<h4>Loading...</h4>}
                      >

                     {items && items.map((item,index)=>(
                    <div   className='reelSection2' style={{position:'relative'}}>


                     {item && item?.file_type === 'image/jpeg' || storyData && storyData?.file_type === 'image/jpeg' ?(

                     <img src={reelId ?  storyData && storyData?.file : item?.file} alt="Story" style={{width:'100%',height:'100vh'}}/> 
                ):(
                    <video ref={videoRef}  alt="Reuters" typeof='video/mp4' autoPlay loop  className="imgDiv2" style={{width:'100%',height:'100vh',objectFit:'cover'}} src={reelId ?  storyData && storyData?.file : item?.file} 
                    onClick={handleVideoClick}/>
                )}
                     


<div style={{position:'absolute',top:'20px',left:'20px'}}>
<Link to="/dashboard">
<div className='dislike2' style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                   <MdOutlineArrowBackIos style={{fontSize:'20px',color:'white',cursor:'pointer'}}/>
  </div>
  </Link>
</div>

<div style={{position:'absolute',display:'flex',flexDirection:'column',gap:'20px',alignItems:'center',bottom:'90px',right:'20px'}}>
                   <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                  <div className={like ? 'like2':'dislike2'}>
                   <AiFillLike onClick={handleReelLike}  style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                    <div style={{fontWeight:'500',fontSize:'18px',color:'white'}}>{reelId ?  storyData && storyData?.like_count : reelTimeline && reelTimeline[currentStoryIndex]?.like_count}</div>
                 </div>


                 <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                   <div className='dislike2' style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                   <MdModeComment onClick={()=>setOpen(true)} style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                   <div style={{fontWeight:'500',fontSize:'18px',color:'white'}}>{reelId ?  storyData && storyData?.comment_count : reelTimeline && reelTimeline[currentStoryIndex]?.comment_count}</div>
                   <Drawer 
                   anchor='bottom'
                   open={open} 
                   onClose={()=>setOpen(false)}>
                      <CommentDrawer reelTimeline={reelTimeline} currentStoryIndex={currentStoryIndex} cookieData={cookieData} setOpen={setOpen} comments = {comments} commentLike = {commentLike} commentReplyText={commentReplyText} setCommentReplyText={setCommentReplyText} handleReelCommentLike={handleReelCommentLike} createCommentReply={createCommentReply} createComment={createComment} commentText={commentText} setCommentText={setCommentText}/>
                   </Drawer>
                   </div>

                   <div className='dislike2' style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px'}}>
                   <PiShareFatFill style={{fontSize:'30px',color:'white',cursor:'pointer'}}/>
                   </div>
                </div>


                <div style={{height:'auto',padding:'0px 10px',borderRadius:'10px',position:'absolute',bottom:'30px',left:'10px'}}>
                  <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                <div style={{height:'50px',width:'50px',border:'2px solid #D8B471',borderRadius:'50px',left:'10px',top:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                          <img src='https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?t=st=1712910396~exp=1712913996~hmac=87fc987f7809e106e86cfb13ffec013049e3add7e0387d7ecf637021789a4383&w=996' style={{height:'40px',width:'43px',borderRadius:'50px'}}/>
                                      </div>
                                      <div>
                                          <div style={{color:'white',fontWeight:'500',fontSize:'22px'}}>{item?.full_name}</div>
                                         
                                      </div>

                  </div>
                  <div style={{fontSize:'24px',color:'white'}}>{item?.message}</div>
                                     
                </div>

                    </div>
                    ))} 
                      </InfiniteScroll>
                    
    </>
  )
}

export default ReelPage

import { Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'

const ProfileCard = () => {
  return (
    <div>
         <Card style={{height:'auto'}}>
            <CardContent>

            
            <Typography variant="h5" gutterBottom style={{fontWeight:'600'}}>
              Profile Details
            </Typography>

            {/* Name Section */}
            <Typography variant="h6">Name</Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} lg={6} xs={12}>
                <Typography style={{color:'black'}}>First Name</Typography>
                <TextField variant='outlined'  defaultValue="Rupesh" fullWidth />
              </Grid>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>Last Name</Typography>
                <TextField variant='outlined'  defaultValue="Mandal" fullWidth />
              </Grid>
            </Grid>
            <Divider style={{margin:'30px 0px'}} />

            {/* Address Section */}
            <Typography variant="h6">Address</Typography>
            <Typography style={{color:'black'}}>Address</Typography>
            <TextField
              
              variant='outlined'
              defaultValue="Haridwar, Uttarakhand, India, 249401"
              fullWidth
              
            />
             <Typography style={{color:'black',marginTop:'10px'}}>City / Town</Typography>
            <TextField
              
              variant='outlined'
              defaultValue="Haridwar, Uttarakhand, India, 249401"
              fullWidth
             
              
            />
            <Grid container spacing={2} style={{marginTop:'10px'}}>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>ZIP / Postal Code</Typography>
                <TextField variant='outlined'   fullWidth />
              </Grid>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>State / Region</Typography>
                <TextField variant='outlined'    fullWidth />
              </Grid>
            </Grid>

           
            <Typography style={{color:'black',marginTop:'10px'}}>Country</Typography>
            <TextField
              
              variant='outlined'
              defaultValue="INDIA"
              fullWidth
              
            />
            <div>In order to change your country, we require you to verify your identity . This is so we can verify your address details. The country will be automatically updated once the process is complete.</div>

            <Typography style={{color:'black',marginTop:'10px'}}>Company</Typography>
            <TextField
              
              variant='outlined'
              defaultValue="Company"
              fullWidth
              
            />

       <Grid container spacing={2} style={{marginTop:'10px'}}>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>Time Zone</Typography>
                <TextField variant='outlined'   fullWidth />
              </Grid>
              <Grid item sm={12} lg={6} xs={12}>
              <Typography style={{color:'black'}}>Location</Typography>
                <TextField variant='outlined'    fullWidth />
              </Grid>
            </Grid>


            <Button variant='contained' style={{background:'blue',marginTop:'20px',color:'white'}}>Save Setting</Button>
            </CardContent>
          </Card>
    </div>
  )
}

export default ProfileCard

import React from 'react'
import FreelancerNavbar from '../FreelancerNavbar'
import { Card, CardContent } from '@material-ui/core'

const DashboardPage = () => {
  return (
    <div>
        <FreelancerNavbar/>
      <div style={{paddingTop:'90px',width:'100%',display:'flex',justifyContent:'center'}}>
        <div style={{width:'80%',display:'flex',gap:'40px'}}>
          <div style={{width:'65%'}}>
             <Card style={{height:'auto',width:'100%'}}>
                <CardContent>
                    <div style={{display:'flex',gap:'20px'}}>
                        <div style={{height:'60px',width:'60px',border:'1px solid black'}}></div>
                        <div>
                          <div>Divya K.. released a milestone payment of 4,000 INR for <spam>azure application ajdh kjsdb kjbskjd</spam></div>
                          <div>about 22 hours ago</div>

                          <div style={{fontWeight:'500',color:'black',marginTop:'20px'}}>Milestone Released</div>
                          <div>Detailed component level skdnh kjhsjd kjhkjsd kjdf.</div>
                          <button  style={{background:'#C40D6C',color:'white',height:'40px',padding:'5px 20px',borderRadius:'3px',marginTop:'10px',border:'none',fontSize:'20px',fontWeight:'500'}}>View Milestone</button>
                        </div>
                    </div>
                </CardContent>
             </Card>
          </div>

          <div style={{width:'30%'}}>
              <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                <div style={{fontSize:'18px',fontWeight:'500',color:'black'}}>Balance</div>
                <div style={{fontSize:'18px',fontWeight:'500',color:'#C40D6C'}}>+ Add Funds</div>
              </div>

              <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                <div style={{fontSize:'18px'}}>Indian Rupee</div>
                <div style={{fontSize:'18px'}}>₹000.00</div>
              </div>

              <hr></hr>


              <div>
                <img src='https://www.f-cdn.com/assets/main/en/assets/dashboard/widgets/zero-commission/zero-commission-v3.png'/>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage

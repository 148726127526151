import React, { useEffect, useState } from 'react'
import { FaFlag } from "react-icons/fa";
import Cookies from 'js-cookie';
import Axios from 'axios';
import toastr from 'reactjs-toastr/lib/react-toast';
import { SiRocketdotchat } from 'react-icons/si';

const PlaceBid = ({detail,bid,bidId}) => {
   
    const [bidAmount, setBidAmount] = useState(bid?bid?.price:300);
    const [deliveryDays, setDeliveryDays] = useState(bid?bid?.timeline:'7');
    const feePercentage = 10;
    const fee = (bidAmount * feePercentage) / 100;
    const amountAfterFee = bidAmount - fee;
    const [cookieData, setCookieData] = useState({});
    const[message,setMessage] = useState(bid?bid?.message:'')
   const [show,setShow] = useState(false);

    const handlePlaceBid = async() => {
        if(Object.keys(cookieData).length > 0 ){
        const data = {
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "message":message,
          "price":bidAmount,
          "timeline":deliveryDays,
          "project_id": detail?.id,
          
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/add_bid`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
            toastr.success('Your bid has successfully placed!')
              setMessage('')
              return true
           }
        }
      }

      const handleEditBid = async() => {
        if(Object.keys(cookieData).length > 0 && bidId ){
        const data = {
          "user_id":cookieData?.id,
          "api_key":cookieData?.api_key,
          "message":message,
          "price":bidAmount,
          "timeline":deliveryDays,
          "id":bidId,
          
        }
        const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/update_bid`,
          data,
          {
            headers:{
              'Content-Type': 'text/plain',
              'Accept':'application/json, text/plain, */*'
            }
           });
           if(response){
            toastr.success('Your bid has successfully updated!')
              setMessage('')
              return true
           }
        }
      }

         const handleBidData = () => {
            if(Object.keys(bid).length > 0 ){
                setShow(true)
            }
         }

         useEffect(()=>{
            handleBidData()
         },[bidId,bid])



     

    useEffect(() => {
        let data = Cookies.getJSON('name');
        setCookieData(data?.user_data)
      }, []);

  return (
    <div>
      <div className='freelancer-name-div'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px",marginTop:"20px"}}>
            <div style={{display:'flex',justifyContent:"space-between"}}>
                <h5 style={{color:"black"}}>Place a bid on this project</h5>
            </div>


            <div style={{padding: "20px 0",borderRadius: "8px",margin: "20px auto",fontFamily: "Arial, sans-serif"}}>
                <div className='column2' style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{ marginBottom: "20px",width:"46%" }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>Bid Amount</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <span style={{ marginRight: "8px" }}>$</span>
                        <input type="number"
                            value={bidAmount}
                            onChange={(e) => setBidAmount(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>USD</span>
                        </div>
                    </div>

                    <div style={{ marginBottom: "20px",width:"46%"  }}>
                        <label style={{ display: "block", marginBottom: "8px",color:"black" }}>This project will be delivered in</label>
                        <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                        <input type="number"
                            value={deliveryDays}
                            onChange={(e) => setDeliveryDays(e.target.value)}
                            style={{
                            border: "none",
                            outline: "none",
                            padding: 0
                            }}
                        /><span style={{ marginLeft: "8px" }}>Days</span>
                        </div>
                    </div>

                </div>

                <div style={{ marginBottom: "20px", fontSize: "14px", color:"black" }}>
                    Paid to you: ${bidAmount} - ${fee} fee = ${amountAfterFee}
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <label style={{ display: "block", marginBottom: "8px",color:"black",fontWeight:"bold" }}>Describe your proposal (minimum 100 characters)</label>
                    <textarea
                    placeholder="What makes you the best candidate for this project?"
                    value={message} onChange={(e)=>setMessage(e.target.value)}
                    style={{width: "100%",border: "1px solid #444",borderRadius: "4px",padding: "10px",color: "black",minHeight: "100px",outline: "none"}}/>
                </div>
                {
                    show ? (
            <div  style={{width:'100%',display:'flex',justifyContent:'end'}}>
                    <button onClick={handleEditBid} style={{border:'1px solid black',padding:'5px 10px',borderRadius:'3px'}}>Edit Bid</button>
                </div>

                    ):(
                    <div  style={{width:'100%',display:'flex',justifyContent:'end'}}>
                    <button onClick={handlePlaceBid} style={{border:'1px solid black',padding:'5px 10px',borderRadius:'3px'}}>Place Bid</button>
                </div>
                    )
                }

                <div style={{color:"black",paddingTop:"20px",fontWeight:"bold"}}>Request milestone payments</div>

                <div style={{padding:"10px 0"}}>Please submit a detailed project proposal outlining how you plan to tackle this task and how quickly you can get started. Your proposal should also demonstrate your typing proficiency and attention to detail. Past work and experience can be referenced, but the focus should be on a clear, comprehensive plan to get this project completed as soon as possible.</div>


                <div style={{display:"flex",gap:"8px"}}>
                    <div style={{ margin: "10px 0",width:"100%" }}>
                    <label style={{ display: "block", marginBottom: "8px",color:"black",fontWeight:"bold" }}>Description</label>

                        <input type='text'
                        placeholder="Project milestone"
                        style={{width: "100%",border: "1px solid #444",borderRadius: "4px",padding: "10px",color: "black",outline: "none"}}/>

                    </div>

                    <div style={{ margin: "10px 0" }}>
                    <label style={{ display: "block", marginBottom: "8px",color:"black",fontWeight:"bold" }}>Amount</label>

                    <div style={{display: "flex",alignItems: "center",border: "1px solid #444",borderRadius: "4px",padding: "8px",}}>
                            <span style={{ marginRight: "8px" }}>$</span>
                            <input type="number"
                                value={bidAmount}
                                // onChange={(e) => setBidAmount(e.target.value)}
                                style={{
                                border: "none",
                                outline: "none",
                                padding: 0
                                }}
                            /><span style={{ marginLeft: "8px" }}>USD</span>
                        </div>

                    </div>

                        

                </div>

            </div>
        </div>
    </div>
  )
}

export default PlaceBid

import React, { useEffect, useState } from 'react'

import { CiStar } from "react-icons/ci";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { HiMiniCurrencyDollar } from "react-icons/hi2";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Axios from 'axios';
import Cookies from 'js-cookie';
import toastr from 'reactjs-toastr/lib/react-toast';
import Messaging from '../../messaging';
import { CardContent, Dialog } from '@material-ui/core';
import Reward from './Reward';


const ProjectProposals = ({bidsData,cookieData,detail,setLoad,load,setDetails,setBidId,details}) => {
  const [chatUser, setChatUser] = useState([]);
    const [msgTextareaVal, setMsgTextareaVal] = useState(''); // If msg_textarea_val is relevant
    const [msgTextareaId, setMsgTextareaId] = useState(null);
    const[open,setOpen] = useState(false);
  const deleteBid = async(id) => {
    const data = {
      "id":id,
      "user_id":cookieData?.id,
      "api_key":cookieData?.api_key
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/delete_bid`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });
       if(response){
        toastr.success('Your bid has successfully retreated!')
         setLoad(!load)
          console.log(response,'response')
          return true
       }
  }

  const [scrollBox, setScrollBox] = useState(null);

  const selectUser = (item) => {
     

      Axios.post(
          `${process.env.REACT_APP_URL}/api/users/start_chat`, 
          { 
              'user_id': cookieData.id, 
              'friend_id': item?.user_id, 
              'api_key': cookieData.api_key 
          }, 
          {  headers:{
            'Content-Type': 'text/plain',
            'Accept':'application/json, text/plain, */*'
          } }
      ).then((res) => {
          // On success
          if (res.data.code === true) {
              currentChating();

              setTimeout(() => {
                  const chatBox = document.getElementById(`scroll_chat_box${item?.user_id}`);
                  if (chatBox) {
                      chatBox.scrollTop = chatBox.scrollHeight;
                  }
              }, 1000);
          }
      }).catch((error) => {
          // Handle error
          console.error("Error starting chat:", error);
      });
  };

  const currentChating = async() => {
   
    const data = {
      "user_id":cookieData?.id,
      "api_key":cookieData?.api_key
    }
    const response = await Axios.post(`${process.env.REACT_APP_URL}/api/users/current_chat`,
      data,
      {
        headers:{
          'Content-Type': 'text/plain',
          'Accept':'application/json, text/plain, */*'
        }
       });

       if(response.data.code === true){
           setChatUser(response?.data)
       }
};

console.log(chatUser,'chatusr')

  const handleBid = (bidId) => {
    setBidId(bidId)
    setDetails('details')
  }

 

 
  
  return (

    <>
    <div className='freelancer-name-div' style={{width:"90%"}}>

    <div style={{marginBottom:'40px'}}>
      {bidsData && bidsData.map((freelancer, index) => (
        <div className='freelancer-name-div'  style={{padding:"10px",border:"1px solid #E2E2E4",borderRadius:"5px",marginTop:"10px"}}>
            <div className='column3' style={{borderRadius: "8px",display: "flex",alignItems: "flex-start",backgroundColor: "#FFF",justifyContent:"space-between"}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <img
                    className='image-size-reduce'
                    src={freelancer.image}
                    alt={`${freelancer.name}'s profile`}
                    style={{width: "96px",height: "96px",borderRadius: "4px",marginRight: "16px"}}
                    />
                    <div >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <strong style={{ fontSize: "18px", marginRight: "8px" }}>{freelancer?.full_name}</strong>
                            <span style={{ color: "#777", fontSize: "16px" }}>@Rupesh7759</span>
                            
                        </div>

                        <div className='freelancer-name-div' style={{display:"flex",gap:"20px"}}>
                            <div style={{display:'flex',alignItems:'center',gap:"4px"}}>
                                <CiStar style={{fontSize:"25px"}}/>
                                0.0
                            </div>
                            
                            <div style={{display:'flex',alignItems:'center',gap:"8px"}}>
                                <BsChatLeftDotsFill style={{fontSize:"20px"}}/>
                                {freelancer.reviews}
                            </div>

                        </div>
            
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                    
                        <div style={{display:'flex',alignItems:'center',gap:"8px", marginRight: "8px"}}>
                                <HiMiniCurrencyDollar style={{fontSize:"20px",color:'green'}}/>
                                {freelancer.earningsScore}
                            </div>
                        
                        <span style={{ color: "#2196F3", marginRight: "8px", fontSize: "16px" }}>
                        {freelancer.completionRate}%
                        </span>
                        <span style={{ fontSize: "16px" }}>{freelancer.location}</span>
                    </div>

                </div>
        
                </div>
                <div className='row' style={{display:'flex', flexDirection:"column",padding:"10px"}} >
                    <h4 className='font-small' style={{color:"black"}}>${freelancer?.price}</h4>
                    <div >in {freelancer?.timeline} days</div>
                </div>
            </div>
                <div style={{margin:"10px 0"}}>BIOSTATISTICS | WRITING | R | SPSS | PDF</div>
                
                <div>{freelancer?.message}</div>
        {
          freelancer?.user_id === cookieData?.id && (

                <div  style={{ display: "flex", alignItems: "end",justifyContent:"flex-end",marginTop:"10px",gap:'20px' }}>
                  <button onClick={()=>deleteBid(freelancer?.id)} style={{border:'1px solid balck',borderRadius:'3px',padding:'5px 10px',background:'transparent'}}>Retreat</button>
                   <button onClick={()=>handleBid(freelancer?.id)} style={{border:'1px solid balck',borderRadius:'3px',padding:'5px 10px'}}>Edit Bid</button>
                </div>
          )
        }


{
          detail?.user_id === cookieData?.id && (

                <div style={{ display: "flex", alignItems: "end",justifyContent:"flex-end",marginTop:"10px",gap:'20px' }}>
                   <button onClick={()=>setOpen(true)}  style={{border:'1px solid balck',borderRadius:'3px',padding:'5px 10px',background:'transparent'}}>Reward</button>
                   <Dialog open={open} onClose={()=>setOpen(false)} maxWidth='sm' fullWidth>
                    <CardContent>
                        <Reward setOpen={setOpen} detail={detail} cookieData={cookieData} bidId={freelancer} setDetails={setDetails}/>
                    </CardContent>
                    </Dialog> 
                  <button onClick={()=>selectUser(freelancer)} style={{border:'1px solid balck',borderRadius:'3px',padding:'5px 10px',background:'transparent'}}>Chat</button>    
                </div>
          )
        }
  
          </div>
      ))}
    </div>
      
    </div>

      <Messaging/>

    </>
  )
}

export default ProjectProposals

import { Card } from '@material-ui/core'
import React from 'react'
import FreelancerNavbar from '../FreelancerNavbar'

const InsightBids = () => {
    const content = [{},{},{},{},{}]
  return (
    <div>
        <FreelancerNavbar/>
       <div style={{width:'100%',display:'flex',justifyContent:'center',paddingTop:'90px'}}>
         <Card style={{width:'97%',height:'auto'}}>
      <div style={{ width: '100%', overflowX: 'auto' }}>
                          <table style={{ width: '100%', minWidth: "870px" }}>
                              <thead style={{width:'100%'}}>
                                  <tr>
                                      
                                      <th align='center' style={{ padding: '10px', width: "250px", }}>Project</th>
                                      <th align='center' style={{ padding: '10px',textAlign:'center' }}>Time to bid</th>
                                      <th align='center' style={{ padding: '10px',textAlign:'center' }}>Bid Rank</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Winning Bid</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Your Bid</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Actions Taken</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Client Info</th>
                                      <th  style={{ padding: '10px',textAlign:'center' }}>Chat Initiated</th>
                                  </tr>
                              </thead>
                              <tbody>
  
                                  {content && content.map((item,index)=>(
                                  <tr style={{borderTop:'0.3px solid rgba(0,0,0,0.1)'}}>
                                   
                                      <td  style={{ padding: '10px' }}>XYZ PRojects</td>
                                      <td  style={{ padding: '10px',textAlign:'center' }}>41</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>$15.00 USD</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>$8.00 USD / hour</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>27 minutes ago</td>
                                      <td style={{ padding: '10px',textAlign:'center' }}>Edit</td>
                                  
                                      
                                  </tr>
  
                                  ))}
                              </tbody>
                          </table>
                      </div>
      </Card>

       </div>
    </div>
  )
}

export default InsightBids
